import React, { useEffect, useState, lazy } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  clearAdminsListAndSuperAdminsList,
  selectUserData,
  updateSuggestionsGiven,
  updateSuggestionsReceived,
  updateUserDetails,
} from "../slices/userSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BulkUpload from "../components/BulkUpload";
import "./UserPage.scss";
import SuggestionsReceived from "../components/SuggestionsReceived";
import SuggestionsGiven from "../components/SuggestionsGiven";
import ImageBulkUpload from "../components/ImageBulkUpload";
import BookMarksList from "../components/BookMarksList";
import WordSuggestions from "../components/WordSuggestions";
const AdminsData = lazy(() => import("../components/AdminsData"));

const UserPage = ({ setSelectedWordAndLang }) => {
  const user = useSelector(selectUserData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    event.preventDefault();
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (location.search) {
      const searchData = location.search?.split("=");
      const query = searchData?.[0]?.substring(1, searchData?.[0].length);
      const param = searchData?.[1];
      if (query === "redirectFromAddNewSuggestion" && param) {
        setExpanded("panel3");
      }
    }
  }, [location]);

  useEffect(() => {
    if (user.userId === "" || user.userId !== params.userId) {
      navigate("/");
    }
  }, [user]);

  const logout = () => {
    signOut(auth);
    dispatch(
      updateUserDetails({
        userId: "",
        name: "",
        photoURL: "",
        emailId: "",
        mobileNumber: "",
        type: "",
        apiKey: "",
        myBookMarks: [],
      })
    );
    dispatch(updateSuggestionsGiven([]));
    dispatch(updateSuggestionsReceived([]));
    dispatch(clearAdminsListAndSuperAdminsList());
    navigate("/");
  };

  return (
    <div className="user-page-wrapper">
      <div className="user-page-section">
        {user && user.userId !== "" ? (
          <>
            <div className="user-name-section">
              <h1>ನಮಸ್ಕಾರ {user?.name}</h1>
              <button className="logoutBtn" onClick={logout}>
                <div className="sign">
                  <svg viewBox="0 0 512 512">
                    <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                  </svg>
                </div>

                <div className="logoutText">ಹೊರಗೆ</div>
              </button>
            </div>
            {user &&
              (user?.type === "admin" || user?.type === "super-admin") && (
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  sx={{ width: "95%" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      sx={{
                        width: "80%",
                        flexShrink: 0,
                        fontWeight: 600,
                        fontSize: "20px",
                      }}
                    >
                      ಡೇಟಾ ಕಟ್ಟಿನ ತಾಣ
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <BulkUpload />
                  </AccordionDetails>
                </Accordion>
              )}
            {user && user?.type === "super-admin" && (
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                sx={{ width: "95%" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    sx={{
                      width: "80%",
                      flexShrink: 0,
                      fontWeight: 600,
                      fontSize: "20px",
                    }}
                  >
                    ನಡೆಸುಗರ ಪಟ್ಟಿ
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AdminsData />
                </AccordionDetails>
              </Accordion>
            )}
            {user &&
              (user?.type === "admin" || user?.type === "super-admin") && (
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                  sx={{ width: "95%" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      sx={{
                        width: "80%",
                        flexShrink: 0,
                        fontWeight: 600,
                        fontSize: "20px",
                      }}
                    >
                      ಸಲಹೆಗಳ ಪಟ್ಟಿ
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SuggestionsReceived />
                  </AccordionDetails>
                </Accordion>
              )}
            {user &&
              (user?.type === "admin" || user?.type === "super-admin") && (
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                  sx={{ width: "95%" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      sx={{
                        width: "80%",
                        flexShrink: 0,
                        fontWeight: 600,
                        fontSize: "20px",
                      }}
                    >
                      ಚಿತ್ರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡುವ ವಿಭಾಗ
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ImageBulkUpload />
                  </AccordionDetails>
                </Accordion>
              )}
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              sx={{ width: "95%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "80%",
                    flexShrink: 0,
                    fontWeight: 600,
                    fontSize: "20px",
                  }}
                >
                  ನನ್ನ ನೆಚ್ಚಿನ ಪದಗಳು
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BookMarksList
                  setSelectedWordAndLang={setSelectedWordAndLang}
                />
              </AccordionDetails>
            </Accordion>
            {user &&
              (user?.type === "admin" || user?.type === "super-admin") && (
                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
                  sx={{ width: "95%" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      sx={{
                        width: "80%",
                        flexShrink: 0,
                        fontWeight: 600,
                        fontSize: "20px",
                      }}
                    >
                      ಪದ ಸಲಹೆಗಳು
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <WordSuggestions />
                  </AccordionDetails>
                </Accordion>
              )}
            {user && user?.type === "user" && <SuggestionsGiven />}
          </>
        ) : (
          <span className="warning-text">
            ನೀವು ಲಾಗ್ ಇನ್ ಆಗದ ಹೊರತು ನಿಮ್ಮ ಪುಟವನ್ನು ನೀವು ನೋಡಲಾಗುವುದಿಲ್ಲ*
          </span>
        )}
      </div>
    </div>
  );
};

export default React.memo(UserPage);
