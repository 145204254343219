import React from "react";
import "./AboutUsPage.scss";

const AboutUsPage = () => {
  return (
    <div className="about-us-wrapper">
      <div className="about-us-section">
        <h1>ಈ ತಾಣದ ಗುರಿ: </h1>
        <br />
        <h3>
          ವಿಜ್ಞಾನ ತಂತ್ರಜ್ಞಾನ ವಿಷಯಗಳನ್ನು ಕನ್ನಡದಲ್ಲಿ ತರಲು ಹೊರಟಾಗ ಎದುರಾಗುವ ಮುಖ್ಯ
          ಎರಡು ಸವಾಲುಗಳೆಂದರೆ:
        </h3>
        <ul>
          <li>ಇಂಗ್ಲೀಶ್ ಪದಗಳಿಗೆ ಸಾಟಿಯಾದ ಕನ್ನಡ ಪದಗಳು ಇಲ್ಲದಿರುವುದು</li>
          <li>ಪದಗಳು ಸುಲಭವಾಗಿ ಡಿಜಿಟಲ್ ರೂಪದಲ್ಲಿ ದೊರೆಯದಿರುವುದು.</li>
        </ul>
        <h3>
          ಮೊದಲನೆಯ ಸಮಸ್ಯೆಯನ್ನು ಎದುರಿಸಲು ನಮ್ಮ ತಂಡ ಕೆಲವು ವರುಶಗಳಿಂದ ಪದಕಟ್ಟಣೆಯ
          ಕೆಲಸವನ್ನು, ಕನ್ನಡ ಸಮುದಾಯದ ಯೋಜನೆಯಾಗಿ ನಡೆಸುತ್ತಾ ಬಂದಿದೆ. ಉದಾಹರಣೆಗೆ, ಪದ ಪದ
          ಕನ್ನಡ ಪದಾನೇ ಅನ್ನುವ ಫೇಸಬುಕ್ ಗುಂಪು 2012 ರಿಂದ ಪದಕಟ್ಟಣೆಯಲ್ಲಿ ತೊಡಗಿದೆ.
          ಜೊತೆಗೆ, ಹಲವು ಬಾರಿ ಪದಕಟ್ಟಣೆಯ ಕಮ್ಮಟಗಳನ್ನು ಹಮ್ಮಿಕೊಂಡಿದೆ.{" "}
        </h3>
        <br />
        <h3>
          ಮುಂದುವರೆಯುತ್ತಾ, ಮೇಲೆ ತಿಳಿಸಿದ ಎರಡನೇ ಸಮಸ್ಯೆಯನ್ನು ಎದುರಿಸಲು ಕಟ್ಟಿದ ತಾಣವೇ ಈ
          "ತಿಳಿಪದ". ಇಂಗ್ಲೀಶ್‍ನ ವಿಜ್ಞಾನ ತಂತ್ರಜ್ಞಾನ ಪದಗಳಿಗೆ, ತಿಳಿಯಾದ ಕನ್ನಡ ಪದಗಳು
          ಒಂದೆಡೆ, ಡಿಜಿಟಲ್ ರೂಪದಲ್ಲಿ ದೊರೆಯಬೇಕು, ಹೊಸ ಪದಗಳನ್ನು ಸೇರಿಸಲು ಇಲ್ಲವೇ ಈಗಿರುವ
          ಪದಗಳಿಗೆ ತಿದ್ದುಪಡಿ ಸೂಚಿಸಲು, ಜನಸಾಮಾನ್ಯರು ಪಾಲ್ಗೊಳ್ಳಬಹುದಾದ ಒಂದು ವೇದಿಕೆ
          ಇದಾಗಬೇಕು ಎನ್ನುವ ಗುರಿಯನ್ನಿಟ್ಟುಕೊಂಡು ಹೊಮ್ಮಿದ ತಾಣವೇ "ತಿಳಿಪದ"
        </h3>
        <br />
        <h3>
          ಹೆಚ್ಚೆಚ್ಚು ಕನ್ನಡಿಗರು "ತಿಳಿಪದ" ಬಳಸಿದಂತೆ ಇಲ್ಲಿರುವ ಪದಗಳ ಪಟ್ಟಿ ಮತ್ತು ಪದಗಳ
          ಗುಣಮಟ್ಟ ಹೆಚ್ಚುತ್ತಾ ಹೋಗುತ್ತದೆ ಎಂಬುದು ನಮ್ಮ ತಂಡದ ನಂಬಿಕೆ.
        </h3>
        <h3 style={{ margin: "10px 0" }}>
          ಈ ಕುರಿತು ಅನಿಸಿಕೆ ತಿಳಿಸಬೇಕೆಂದರೆ ಈ ಕೆಳಗಿನ ವಿಳಾಸಕ್ಕೆ ಮಿಂ‍ಚಿಸಬಹುದು:{" "}
          <a href="mailto:munnotaweb@gmail.com" target="_blank">
            munnotaweb@gmail.com
          </a>
        </h3>
      </div>
    </div>
  );
};

export default React.memo(AboutUsPage);
