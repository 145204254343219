import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  toastMessage: {
    show: false,
    message: "",
    isError: false,
    isInfo: false,
    isSuccess: false,
    additionalErrorMessages: [],
  },
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    updateLoadingStatus: (state, action) => {
      state.loading = action.payload;
    },
    updateToastMessage: (state, action) => {
      state.toastMessage = action.payload;
    },
  },
});

export const { updateLoadingStatus, updateToastMessage } = settingSlice.actions;

export const selectLoadingStatus = (state) => state.setting.loading;
export const selectToastMessage = (state) => state.setting.toastMessage;

export default settingSlice.reducer;
