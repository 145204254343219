import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  selectSuggestionsGiven,
  selectUserData,
  updateSuggestionsGiven,
} from "../slices/userSlice";
import { Link } from "react-router-dom";
import { collection, getDocs, limit, query } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SuggestionsGiven = () => {
  const user = useSelector(selectUserData);
  const suggestionsGiven = useSelector(selectSuggestionsGiven);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (user.type === "user") {
      const q = query(
        collection(
          db,
          process.env.REACT_APP_USERS_COLLECTION,
          user.userId,
          process.env.REACT_APP_SUGGESTIONS_GIVEN_COLLECTION
        ),
        limit(100)
      );
      const querySnapshot = await getDocs(q);
      dispatch(
        updateSuggestionsGiven(querySnapshot.docs.map((doc) => doc.data()))
      );
    }
  };

  return (
    <div className="glossary-page-wrapper">
      <div
        className="glossary-page-section"
        style={{ minHeight: "fit-content" }}
      >
        <span>
          ಹೊಸ ಪದವನ್ನು ಸೂಚಿಸಲು ದಯವಿಟ್ಟು{" "}
          <Link to={"/add-new-suggestion"}>ಇಲ್ಲಿ ಒತ್ತಿ</Link>
        </span>
        <div
          className="table-container"
          style={{ height: "fit-content", width: "100%", marginTop: "10px" }}
        >
          {suggestionsGiven.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{ minWidth: 150, fontSize: 20, fontWeight: 600 }}
                    >
                      ಕನ್ನಡ ಪದ
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ minWidth: 150, fontSize: 20, fontWeight: 600 }}
                    >
                      ಆಂಗ್ಲ ಪದ
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        minWidth: 100,
                        fontSize: 20,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      ಪ್ರಸ್ತುತ ಸ್ಥಿತಿ
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suggestionsGiven.map((data, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row" align="left">
                        {data.searchKannadaWord[0]}
                      </StyledTableCell>
                      <StyledTableCell align="left" scope="row">
                        {data.searchEnglishWord[0]}
                      </StyledTableCell>
                      <StyledTableCell align="left" scope="row">
                        {data.isSuggestionAccepted ? (
                          <span style={{ color: "green" }}>
                            ಸಲಹೆಯನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ
                          </span>
                        ) : data.isSuggestionAccepted === undefined ? (
                          <span>ಇನ್ನೂ ಪರಿಶೀಲಿಸಲಾಗಿಲ್ಲ</span>
                        ) : (
                          <span style={{ color: "red" }}>
                            ಸಲಹೆಯನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿಲ್ಲ
                          </span>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuggestionsGiven;
