import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeABookMark, selectUserData } from "../slices/userSlice";
import { Box, Chip } from "@mui/material";
import { truncateText } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { updateToastMessage } from "../slices/settingsSlice";

const BookMarksList = ({ setSelectedWordAndLang }) => {
  const user = useSelector(selectUserData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleWordSelected = (e, word, isKannada) => {
    e.preventDefault();
    setSelectedWordAndLang({ word, isKannada });
    navigate("/");
  };

  const handleDeleteWord = async (objectToRemove) => {
    try {
      const userRef = doc(
        db,
        process.env.REACT_APP_USERS_COLLECTION,
        user.userId
      );
      await updateDoc(userRef, {
        myBookMarks: arrayRemove(objectToRemove),
      });
      dispatch(
        removeABookMark({
          word: objectToRemove.word,
          bookmarks: user.myBookMarks,
        })
      );

      dispatch(
        updateToastMessage({
          show: true,
          message: "ಈ ಪದವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ!",
          additionalErrorMessages: [],
          isError: false,
          isInfo: false,
          isSuccess: true,
        })
      );
    } catch (error) {
      console.error("Error deleting bookmark:", error);
      dispatch(
        updateToastMessage({
          show: true,
          message: "ಈ ಪದವನ್ನು ಅಳಿಸುವಾಗ ದೋಷ ಕಂಡುಬಂದಿದೆ.",
          additionalErrorMessages: [],
          isError: true,
          isInfo: false,
          isSuccess: false,
        })
      );
    }
  };

  return (
    <div className="suggestions-page-wrapper">
      {user.myBookMarks?.length > 0 ? (
        <Box
          display="flex"
          flexWrap="wrap"
          gap={1}
          margin={"auto"}
          marginTop={2}
          alignItems={"flex-start"}
          justifyContent={"center"}
          height={"fit-content"}
          maxHeight={150}
        >
          {user.myBookMarks?.map((data, index) => (
            <Chip
              label={truncateText(data.word)}
              key={index}
              onClick={(e) => handleWordSelected(e, data.word, data.isKannada)}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onDelete={() => handleDeleteWord(data)}
              deleteIcon={<DeleteIcon />}
            />
          ))}
        </Box>
      ) : (
        <span className="warning-text">ಯಾವುದೇ ಪದವನ್ನು ಸೇರಿಸಲಾಗಿಲ್ಲ</span>
      )}
    </div>
  );
};

export default BookMarksList;
