import React, { useState } from "react";
import SearchResultHeader from "./SearchResultHeader";
import SearchDetailedDescription from "./SearchDetailedDescription";
import SearchResultShowLinksAndImages from "./SearchResultShowLinksAndImages";
import { findWhetherDataIsPresent } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../slices/userSlice";
import {
  updateDataToEdit,
  updateEditExisting,
  updateSearchResult,
} from "../slices/searchResultSlice";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import YouTubePlayer from "./YouTubePlayer";

const SearchCard = ({
  result,
  index,
  // speak,
  // voice,
  isEnglish,
  setSelectedWordAndLang,
}) => {
  const documentData = result.document;
  const user = useSelector(selectUserData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDeleteConfirmPopUp, setOpenDeleteConfirmPopUp] = useState(false);

  const handleDeleteConfirmPopUpClickOpen = () => {
    setOpenDeleteConfirmPopUp(true);
  };

  const handleDeleteConfirmPopUpClose = () => {
    setOpenDeleteConfirmPopUp(false);
  };

  const capitalizeFirstCharacterForEnglish = (word, isEnglishWord) => {
    let _word = word;
    if (isEnglishWord) {
      const firstLetter = _word.charAt(0);
      const firstLetterCap = firstLetter.toUpperCase();
      const remainingLetters = _word.slice(1);
      _word = firstLetterCap + remainingLetters;
    }
    return _word;
  };

  const handleEditData = (e) => {
    e.preventDefault();
    dispatch(updateDataToEdit(documentData));
    dispatch(updateEditExisting(true));
    navigate("/add-new-suggestion");
  };

  const handleDeleteData = async () => {
    const docRef = doc(
      db,
      process.env.REACT_APP_DICTIONARY_COLLECTION_NAME,
      documentData?.uniqueId
    );
    await deleteDoc(docRef);
    handleDeleteConfirmPopUpClose();
    dispatch(updateSearchResult());
    setSelectedWordAndLang({
      word: "",
      isKannada: !isEnglish,
    });
  };

  return (
    <div className="search-card" key={index}>
      <SearchResultHeader
        isEnglish={isEnglish}
        documentData={documentData}
        // speak={speak}
        // voice={voice}
        index={index}
        capitalizeFirstCharacterForEnglish={capitalizeFirstCharacterForEnglish}
      />
      {(user.type === "super-admin" || user.type === "admin") && (
        <div className="searched-db-val-result-wrapper">
          <button className="edit-button" onClick={handleEditData}>
            <svg className="edit-svgIcon" viewBox="0 0 512 512">
              <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
            </svg>
          </button>
          <button
            className="delete-button"
            onClick={handleDeleteConfirmPopUpClickOpen}
          >
            <svg className="delete-svgIcon" viewBox="0 0 448 512">
              <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
            </svg>
          </button>
        </div>
      )}
      {findWhetherDataIsPresent(documentData?.detailedMeaningDescription) && (
        <>
          <h2 className="search-result-section-headers">ವಿವರಣೆ:</h2>
          <SearchDetailedDescription
            detailedMeaningDescription={
              documentData?.detailedMeaningDescription
            }
          />
        </>
      )}
      {findWhetherDataIsPresent(documentData?.examples) && (
        <>
          <h2 className="search-result-section-headers">ಉದಾಹರಣೆಗಳು:</h2>
          <SearchDetailedDescription
            detailedMeaningDescription={documentData?.examples}
          />
        </>
      )}
      {findWhetherDataIsPresent(documentData?.kannadaSynonyms) && (
        <>
          <h2 className="search-result-section-headers">
            ಕನ್ನಡ ಸಮಾನಾರ್ಥಕ ಪದಗಳು:
          </h2>
          <SearchDetailedDescription
            detailedMeaningDescription={documentData?.kannadaSynonyms}
          />
        </>
      )}
      {findWhetherDataIsPresent(documentData?.englishSynonyms) && (
        <>
          <h2 className="search-result-section-headers">
            ಆಂಗ್ಲ ಸಮಾನಾರ್ಥಕ ಪದಗಳು:
          </h2>
          <SearchDetailedDescription
            detailedMeaningDescription={documentData?.englishSynonyms}
            capitalizeFirstCharacterForEnglish={
              capitalizeFirstCharacterForEnglish
            }
            isEnglish={true}
          />
        </>
      )}
      {findWhetherDataIsPresent(documentData["meaningCatTypeAndDesc.noun"]) && (
        <>
          <h2 className="search-result-section-headers">ಹೆಸರುಪದಗಳು:</h2>
          <SearchDetailedDescription
            detailedMeaningDescription={
              documentData["meaningCatTypeAndDesc.noun"]
            }
          />
        </>
      )}
      {findWhetherDataIsPresent(
        documentData["meaningCatTypeAndDesc.pronoun"]
      ) && (
        <>
          <h2 className="search-result-section-headers">ಸರ್ವನಾಮ ಪದಗಳು:</h2>
          <SearchDetailedDescription
            detailedMeaningDescription={
              documentData["meaningCatTypeAndDesc.pronoun"]
            }
          />
        </>
      )}
      {findWhetherDataIsPresent(documentData["meaningCatTypeAndDesc.verb"]) && (
        <>
          <h2 className="search-result-section-headers">ಎಸಕಪದಗಳು:</h2>
          <SearchDetailedDescription
            detailedMeaningDescription={
              documentData["meaningCatTypeAndDesc.verb"]
            }
          />
        </>
      )}
      {findWhetherDataIsPresent(
        documentData["meaningCatTypeAndDesc.adjective"]
      ) && (
        <>
          <h2 className="search-result-section-headers">ಪರಿಚೆಪದಗಳು:</h2>
          <SearchDetailedDescription
            detailedMeaningDescription={
              documentData["meaningCatTypeAndDesc.adjective"]
            }
          />
        </>
      )}
      {documentData?.miscInfo && (
        <>
          <h2 className="search-result-section-headers">ಇತರ ಮಾಹಿತಿ:</h2>
          <SearchDetailedDescription
            detailedMeaningDescription={documentData?.miscInfo}
          />
        </>
      )}
      {findWhetherDataIsPresent(documentData?.customLinkUrl) && (
        <SearchResultShowLinksAndImages
          data={documentData?.customLinkUrl}
          isCustomLinkUrls={true}
          isVideoUrls={false}
          isImageUrls={false}
        />
      )}
      {findWhetherDataIsPresent(documentData?.vedioLinkUrls) && (
        <YouTubePlayer urls={documentData?.vedioLinkUrls} />
      )}
      {findWhetherDataIsPresent(documentData?.imageUrls) && (
        <SearchResultShowLinksAndImages
          data={documentData?.imageUrls}
          isCustomLinkUrls={false}
          isVideoUrls={false}
          isImageUrls={true}
        />
      )}
      <Dialog
        open={openDeleteConfirmPopUp}
        onClose={handleDeleteConfirmPopUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"ಅಳಿಸುವಿಕೆಯನ್ನು ಖಚಿತಪಡಿಸಿ"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ಈ ದಾಖಲೆಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ? ಅಳಿಸಿದರೆ, ನೀವು ಈ
            ಕಾರ್ಯಾಚರಣೆಯನ್ನು ರದ್ದುಗೊಳಿಸಲಾಗುವುದಿಲ್ಲ.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmPopUpClose}>ಒಪ್ಪುವುದಿಲ್ಲ</Button>
          <Button onClick={handleDeleteData} autoFocus>
            ಒಪ್ಪುತ್ತೇನೆ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SearchCard;
