import React, { useState } from "react";
import { updateToastMessage } from "../slices/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import axios from "axios";
import ReactGA from "react-ga4";
import { selectUserData } from "../slices/userSlice";

const BulkUpload = () => {
  const user = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [uploadFileName, setUploadFileName] = useState("");
  const [disableOnClickAction, setDisableOnClickAction] = useState(false);
  let formData = new FormData();

  const handleBulkUploadChange = async (e) => {
    if (!disableOnClickAction) {
      setDisableOnClickAction(true);
      const file = e.target.files[0];
      const fileName = file?.name;
      setUploadFileName(fileName);
      const fileExtension = fileName?.split(".")?.pop();
      if (fileExtension === "xlsx") {
        formData.append("files", file);
        axios
          .post(
            `/backend/arime/dictionary/uploadArimeDictionaryData`,
            formData,
            {
              /*  proxy: {
            protocol: process.env.REACT_APP_BACKEND_PROTOCOL,
            host: process.env.REACT_APP_BACKEND_HOST,
            port: process.env.REACT_APP_BACKEND_PORT,
          }, */
              headers: {
                "X-API-KEY": user.apiKey,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            dispatch(
              updateToastMessage({
                show: true,
                message: "ತಿಳಿಪದ ಪದನೆರೆಕೆಗೆ ನಿಮ್ಮ ಕಡತವನ್ನು ಸೇರಿಸಲಾಗಿದೆ.",
                additionalErrorMessages: [],
                isError: false,
                isInfo: false,
                isSuccess: true,
              })
            );
            // Track the upload event with ReactGA
            ReactGA.event({
              category: "Upload",
              action: "Performed upload operation",
              label: `Admin ${
                user?.name ? user.name(user.emailId) : "Unknown"
              } uploaded file: ${fileName}`,
            });
            setDisableOnClickAction(false);
          })
          .catch((error) => {
            let message = "";
            let additionalErrorMessages = [];
            if (error?.response?.data?.message?.length > 0) {
              message = error?.response?.data?.message;
              if (error?.response?.data?.additionalErrorInfo?.length > 0) {
                error?.response?.data?.additionalErrorInfo?.forEach((str) => {
                  additionalErrorMessages.push(str);
                });
              }
            } else {
              message = error?.message;
            }
            if (error?.response?.status && error?.response?.status > 299) {
              dispatch(
                updateToastMessage({
                  show: true,
                  message:
                    "ತೊಂದರೆ- ತಿಳಿಪದ ಪದನೆರೆಕೆಗೆ ನಿಮ್ಮ ಕಡತವನ್ನು ಸೇರಿಸಲಾಗಲಿಲ್ಲ.",
                  additionalErrorMessages,
                  isError: true,
                  isInfo: false,
                  isSuccess: false,
                })
              );
            }
            setDisableOnClickAction(false);
          });
        setUploadFileName("");
      } else {
        dispatch(
          updateToastMessage({
            show: true,
            message: "ದಯವಿಟ್ಟು ಎಕ್ಸೆಲ್ ಫೈಲ್‌ಗಳನ್ನು ಮಾತ್ರ ಆಯ್ಕೆಮಾಡಿ.",
            additionalErrorMessages: [],
            isError: true,
            isInfo: false,
            isSuccess: false,
          })
        );
        setUploadFileName("");
        setDisableOnClickAction(false);
      }
      e.target.value = null;
    }
  };

  const handleBulkDownload = async (e) => {
    if (!disableOnClickAction) {
      setDisableOnClickAction(true);
      const date = new Date();
      const timestamp = `${date.getFullYear()}_${String(
        date.getMonth() + 1
      ).padStart(2, "0")}_${String(date.getDate()).padStart(2, "0")}_${String(
        date.getHours()
      ).padStart(2, "0")}_${String(date.getMinutes()).padStart(
        2,
        "0"
      )}_${String(date.getSeconds()).padStart(2, "0")}`;
      const fileName = "ಅರಿಮೆ ತಿಳಿಪದ ಪದನೆರಕೆ " + timestamp + ".xlsx";
      axios
        .get(`/backend/arime/dictionary/downloadBulkUploadedExcel`, {
          proxy: {
            protocol: process.env.REACT_APP_BACKEND_PROTOCOL,
            host: process.env.REACT_APP_BACKEND_HOST,
            port: process.env.REACT_APP_BACKEND_PORT,
          },
          headers: {
            "X-API-KEY": user.apiKey,
          },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          dispatch(
            updateToastMessage({
              show: true,
              message:
                "ತಿಳಿಪದ ಪದನೆರೆಕೆಯ ಕಡತವನ್ನು ನಿಮ್ಮ ಕಂಪ್ತ್ಯೂಟರ್ ಗೆ ಇಳಿಸಲಾಗಿದೆ.",
              additionalErrorMessages: [],
              isError: false,
              isInfo: false,
              isSuccess: true,
            })
          );
          // Track the download event with ReactGA
          ReactGA.event({
            category: "Download",
            action: "Performed download operation",
            label: `Admin ${
              user?.name ? user.name(user.emailId) : "Unknown"
            } downloaded file: ${fileName}`,
          });
          setDisableOnClickAction(false);
        })
        .catch((error) => {
          let message = "";
          let additionalErrorMessages = [];
          if (error?.response?.data?.message?.length > 0) {
            message = error?.response?.data?.message;
            if (error?.response?.data?.additionalErrorInfo?.length > 0) {
              error?.response?.data?.additionalErrorInfo?.forEach((str) => {
                additionalErrorMessages.push(str);
              });
            }
          } else {
            message = error?.message;
          }
          if (error?.response?.status && error?.response?.status > 299) {
            dispatch(
              updateToastMessage({
                show: true,
                message:
                  "ತೊಂದರೆ- ತಿಳಿಪದ ಪದನೆರೆಕೆಯ ಕಡತವನ್ನು ನಿಮ್ಮ ಕಂಪ್ತ್ಯೂಟರ್ ಗೆ ಇಳಿಸಲಾಗಲಿಲ್ಲ.",
                additionalErrorMessages,
                isError: true,
                isInfo: false,
                isSuccess: false,
              })
            );
          }
          setDisableOnClickAction(false);
        });
    }
  };

  const handleOpenPrompt = (e) => {
    e.preventDefault();
    if (!disableOnClickAction) {
      const response = prompt(
        "ತಿಳಿಪದ ಪದನೆರೆಕೆಯ ಎಲ್ಲಾ ಪದಗಳನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ. ಒಪ್ಪಿಗೆಯಿದ್ದಲ್ಲಿ ಗುಟ್ಟುಪದ ಹಾಕಿ."
      );
      if (response === process.env.REACT_APP_DELETE_PHRASE) {
        handleBulkDelete(response);
      }
    }
  };

  const handleBulkDelete = async (phrase) => {
    setDisableOnClickAction(true);
    axios
      .delete(
        `/backend/arime/dictionary/deleteWholeDictionaryData?passPhrase=${phrase}`,
        {
          headers: {
            "X-API-KEY": user.apiKey,
          },
        }
      )
      .then((response) => {
        dispatch(
          updateToastMessage({
            show: true,
            message: "ಡೇಟಾ ಕಟ್ಟು ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ.",
            additionalErrorMessages: [],
            isError: false,
            isInfo: false,
            isSuccess: true,
          })
        );
        // Track the delete event with ReactGA
        ReactGA.event({
          category: "Delete",
          action: "Performed delete operation",
          label: `Admin ${
            user?.name ? user.name(user.emailId) : "Unknown"
          } deleted database`,
        });
        setDisableOnClickAction(false);
      })
      .catch((error) => {
        let message = "";
        let additionalErrorMessages = [];
        if (error?.response?.data?.message?.length > 0) {
          message = error?.response?.data?.message;
          if (error?.response?.data?.additionalErrorInfo?.length > 0) {
            error?.response?.data?.additionalErrorInfo?.forEach((str) => {
              additionalErrorMessages.push(str);
            });
          }
        } else {
          message = error?.message;
        }
        if (error?.response?.status && error?.response?.status > 299) {
          dispatch(
            updateToastMessage({
              show: true,
              message,
              additionalErrorMessages,
              isError: true,
              isInfo: false,
              isSuccess: false,
            })
          );
        }
        setDisableOnClickAction(false);
      });
  };

  const handleRefresh = async (e) => {
    e.preventDefault();
    const passPhrase = "hosadhbeku";
    if (!disableOnClickAction) {
      setDisableOnClickAction(true);
      try {
        const [response1, response2] = await Promise.all([
          axios.get(
            `/backend/arime/dictionary/public/evictCache?passPhrase=${passPhrase}`,
            {
              proxy: {
                protocol: process.env.REACT_APP_BACKEND_PROTOCOL,
                host: process.env.REACT_APP_BACKEND_HOST,
                port: process.env.REACT_APP_BACKEND_PORT,
              },
              headers: {
                "X-API-KEY": user.apiKey,
                Accept: "*/*",
              },
            }
          ),
          axios.get(
            `/backend/arime/dictionary/public/evictCache?passPhrase=${passPhrase}`,
            {
              proxy: {
                protocol: process.env.REACT_APP_BACKEND_PROTOCOL,
                host: process.env.REACT_APP_BACKEND_HOST,
                port: process.env.REACT_APP_BACKEND_PORT,
              },
              headers: {
                "X-API-KEY": user.apiKey,
                Accept: "*/*",
              },
            }
          ),
        ]);
        const combinedMessage =
          response1.data.Response || response2.data.Response;
        dispatch(
          updateToastMessage({
            show: true,
            message:
              combinedMessage ||
              "ಬಚ್ಚುಗೂಡನ್ನು ತಿಳಿಯಾಗಿಸಲಾಗಿದೆ.\nCache refreshed successfully.",
            additionalErrorMessages: [],
            isError: false,
            isInfo: false,
            isSuccess: true,
          })
        );
        // Track the download event with ReactGA
        ReactGA.event({
          category: "Refresh",
          action: "Cache refresh action triggered",
          label: `Admin clicked on cache refresh button`,
        });
        setDisableOnClickAction(false);
      } catch (error) {
        let message = "";
        let additionalErrorMessages = [];
        if (error?.response?.data?.message?.length > 0) {
          message = error?.response?.data?.message;
          if (error?.response?.data?.additionalErrorInfo?.length > 0) {
            error?.response?.data?.additionalErrorInfo?.forEach((str) => {
              additionalErrorMessages.push(str);
            });
          }
        } else {
          message = error?.message;
        }
        if (error?.response?.status && error?.response?.status > 299) {
          dispatch(
            updateToastMessage({
              show: true,
              message: "ತೊಂದರೆ- ಬಚ್ಚುಗೂಡನ್ನು ತಿಳಿಯಾಗಿಸುವ ಕಾರ್ಯ ವಿಫಲವಾಗಿದೆ.",
              additionalErrorMessages,
              isError: true,
              isInfo: false,
              isSuccess: false,
            })
          );
        }
        setDisableOnClickAction(false);
      }
    }
  };

  return (
    <div className="bulk-data-handle-section">
      <div className="upload-section-wrapper">
        <div className="file-upload">
          <BackupOutlinedIcon />
          <h3>ಡೇಟಾ ಕಟ್ಟು ಸೇರಿಸಿ (Bulk Upload)</h3>
          <p>
            {uploadFileName !== "" ? uploadFileName : "ಎಕ್ಸೆಲ್ ಫೈಲ್ ಆಯ್ಕೆಮಾಡಿ"}
          </p>
          <input type="file" onChange={handleBulkUploadChange} />
        </div>
      </div>
      <div className="upload-section-wrapper" onClick={handleBulkDownload}>
        <div className="file-upload">
          <CloudDownloadOutlinedIcon />
          <h3>ಡೇಟಾ ಕಟ್ಟು ಇಳಿಸಿಕೊಳ್ಳಿ (Bulk Download)</h3>
        </div>
      </div>
      <div className="upload-section-wrapper" onClick={handleOpenPrompt}>
        <div className="file-upload">
          <DeleteForeverOutlinedIcon />
          <h3>ಡೇಟಾ ಕಟ್ಟು ಅಳಿಸುವಿಕೆ (Bulk Delete)</h3>
        </div>
      </div>
      <div className="upload-section-wrapper" onClick={handleRefresh}>
        <div className="file-upload">
          <RefreshRoundedIcon />
          <h3>ಬಚ್ಚುಗೂಡನ್ನು ತಿಳಿಯಾಗಿಸಿ (Evict Cache)</h3>
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
