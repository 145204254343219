import React from "react";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import { addABookMark, selectUserData } from "../slices/userSlice";
import { db } from "../firebase/firebaseConfig";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { updateToastMessage } from "../slices/settingsSlice";
import { isTheWordAlreadyBookMarked } from "../utils/utils";
// import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
// import axios from "axios";

const SearchResultHeader = ({
  isEnglish,
  documentData,
  // speak,
  // voice,
  index,
  capitalizeFirstCharacterForEnglish,
}) => {
  const user = useSelector(selectUserData);
  const dispatch = useDispatch();
  // const [audioUrl, setAudioUrl] = useState("");
  const headerOne = isEnglish
    ? documentData?.englishMeaning
    : documentData?.kannadaMeaning;
  const headerTwo = isEnglish
    ? documentData?.kannadaMeaning
    : documentData?.englishMeaning;

  const isWordAlreadyBookmarked = isTheWordAlreadyBookMarked(
    !isEnglish
      ? documentData?.searchKannadaWord?.[0]
        ? documentData?.searchKannadaWord?.[0]
        : ""
      : documentData?.searchEnglishWord?.[0]
      ? documentData?.searchEnglishWord?.[0]
      : "",
    user.myBookMarks
  );

  const speakHeaderName = (words) => {
    let value = "";
    words?.forEach((word) => {
      value += `${word}, `;
    });
    // speak({ text: value, voice, rate: 1, pitch: 1 });
  };

  const handleTextToSpeech = async (words) => {
    //   try {
    //     const response = await axios.post(
    //       "https://texttospeech.googleapis.com/v1/text:synthesize",
    //       {
    //         input: { text: words[0] },
    //         voice: { languageCode: "kn-IN", name: "kn-IN-Standard-A" }, // Kannada voice
    //         audioConfig: { audioEncoding: "MP3" }, // You can choose other formats too
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer YOUR_API_KEY_OR_ACCESS_TOKEN`,
    //         },
    //       }
    //     );
    //     // Set the audio URL received from the API response
    //     setAudioUrl(response.data.audioContent);
    //   } catch (error) {
    //     console.error("Error converting text to speech:", error);
    //   }
  };
  const handleShare = () => {
    const searchWord = !isEnglish
      ? documentData?.searchKannadaWord?.[0]
        ? documentData?.searchKannadaWord?.[0]
        : ""
      : documentData?.searchEnglishWord?.[0]
      ? documentData?.searchEnglishWord?.[0]
      : "";
    const currentUrl = window.location.href.split("?")[0];
    const shareUrl = `${currentUrl}?word=${searchWord}&isKannada=${!isEnglish}`;
    if (navigator.share) {
      navigator
        .share({
          title: "ಇದನ್ನು ಪರಿಶೀಲಿಸಿ!",
          url: shareUrl,
        })
        .then(() => {
          // alert("ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕೆ ಧನ್ಯವಾದಗಳು!");
        })
        .catch(console.error);
    } else {
      navigator.clipboard
        .writeText(shareUrl)
        .then(() => {
          alert("ಲಿಂಕ್ ಅನ್ನು ಕ್ಲಿಪ್‌ಬೋರ್ಡ್‌ಗೆ ನಕಲಿಸಲಾಗಿದೆ!!");
        })
        .catch(console.error);
    }
  };

  const bookMarkTheWord = async () => {
    const searchedWord = !isEnglish
      ? documentData?.searchKannadaWord?.[0]
        ? documentData?.searchKannadaWord?.[0]
        : ""
      : documentData?.searchEnglishWord?.[0]
      ? documentData?.searchEnglishWord?.[0]
      : "";
    const bookMarkingData = {
      word: searchedWord,
      isKannada: !isEnglish,
    };
    if (isWordAlreadyBookmarked) {
      dispatch(
        updateToastMessage({
          show: true,
          message: "ಈ ಪದವನ್ನು ಈಗಾಗಲೇ ಸೇರಿಸಲಾಗಿದೆ!",
          additionalErrorMessages: [],
          isError: false,
          isInfo: true,
          isSuccess: false,
        })
      );
      return;
    }
    try {
      const userRef = doc(
        db,
        process.env.REACT_APP_USERS_COLLECTION,
        user.userId
      );
      await updateDoc(userRef, {
        myBookMarks: arrayUnion(bookMarkingData),
      });
      dispatch(
        addABookMark({ data: bookMarkingData, bookmarks: user.myBookMarks })
      );
      dispatch(
        updateToastMessage({
          show: true,
          message: "ಈ ಪದವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ!",
          additionalErrorMessages: [],
          isError: false,
          isInfo: false,
          isSuccess: true,
        })
      );
    } catch (error) {
      console.error("Error adding bookmark:", error);
      dispatch(
        updateToastMessage({
          show: true,
          message: "ಈ ಪದವನ್ನು ಸೇರಿಸುವಲ್ಲಿ ದೋಷ ಕಂಡುಬಂದಿದೆ.",
          additionalErrorMessages: [],
          isError: true,
          isInfo: false,
          isSuccess: false,
        })
      );
    }
  };

  return (
    <div className="searched-word-heading">
      {index === 0 && (
        <div className="heading-text-with-mic">
          <div
            className={`${
              !isEnglish ? "kannada-header-text" : "english-header-text"
            } header-text`}
          >
            {headerOne.map((word, i) => (
              <h1 key={i}>
                {capitalizeFirstCharacterForEnglish(word, isEnglish)}
                {headerOne.length - 1 > i && ", "}
              </h1>
            ))}
          </div>
          {/* {voice && window.innerWidth > 1024 && (
            <VolumeUpRoundedIcon
              className="speaker-icon"
              onClick={() => {
                !isEnglish
                  ? handleTextToSpeech(headerOne)
                  : speakHeaderName(headerOne);
              }}
            />
          )} */}
          <div className="user-action-buttons">
            <IconButton onClick={handleShare} aria-label="share">
              <ShareIcon />
            </IconButton>
            {user.userId && (
              <IconButton
                aria-label="Golden Star"
                style={{
                  color: `${isWordAlreadyBookmarked ? "gold" : "dimgray"}`,
                }}
                onClick={bookMarkTheWord}
              >
                <StarIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}
      <div className="heading-text-with-mic">
        <div
          className={`${
            isEnglish ? "kannada-header-text" : "english-header-text"
          } sub-header-text`}
        >
          {headerTwo.map((word, i) => (
            <h1 key={i}>
              {capitalizeFirstCharacterForEnglish(word, !isEnglish)}
              {headerTwo.length - 1 > i && ", "}
            </h1>
          ))}
        </div>
        {/* {voice && window.innerWidth > 1024 && (
          <VolumeUpRoundedIcon
            className="speaker-icon"
            onClick={() => {
              !isEnglish
                ? handleTextToSpeech(headerOne)
                : speakHeaderName(headerOne);
            }}
          />
        )} */}
        {/* {audioUrl && (
          <audio controls>
            <source
              src={`data:audio/mp3;base64,${audioUrl}`}
              type="audio/mp3"
            />
            Your browser does not support the audio element.
          </audio>
        )} */}
      </div>
    </div>
  );
};

export default SearchResultHeader;
