import React, { Fragment } from "react";
// import { useSpeechSynthesis } from "react-speech-kit";
import SearchCard from "./SearchCard";

const SearchResults = ({
  searchResults,
  isEnglish,
  setSelectedWordAndLang,
}) => {
  // const { speak, voices } = useSpeechSynthesis();
  // const voice = voices[173] ? voices[173] : voices[13];

  return (
    <div className="search-results-wrapper">
      <p className="result-count">
        {searchResults?.found ? searchResults?.found : 0}{" "}
        {searchResults?.found > 1 ? "ಫಲಿತಾಂಶಗಳು" : "ಫಲಿತಾಂಶ"} ದೊರೆತಿದೆ
      </p>
      {searchResults?.hits?.map((result, index) => (
        <Fragment key={index}>
          <SearchCard
            result={result}
            index={index}
            // speak={speak}
            // voice={voice}
            isEnglish={isEnglish}
            setSelectedWordAndLang={setSelectedWordAndLang}
          />
        </Fragment>
      ))}
    </div>
  );
};

export default React.memo(SearchResults);
