import React from "react";
import NoDataFoundImg from "../assets/no-data-found.png";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { useDispatch } from "react-redux";
import { updateToastMessage } from "../slices/settingsSlice";

const NoDataFound = ({ isEnglish }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addWordSuggestionToAddByAdmin = async (e) => {
    e.preventDefault();
    let searchedWord = "";
    const searchContainerElement =
      document.getElementsByClassName("search-container")?.[0];
    if (isEnglish) {
      searchedWord =
        searchContainerElement?.childNodes?.[1]?.childNodes?.[0]?.value;
    } else {
      searchedWord =
        searchContainerElement?.childNodes?.[1]?.childNodes?.[1]?.textContent;
    }
    try {
      const collectionRef = collection(db, "word-suggestions");
      await addDoc(collectionRef, { searchedWord });
      dispatch(
        updateToastMessage({
          show: true,
          message: "ಪದವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೂಚಿಸಲಾಗಿದೆ.",
          additionalErrorMessages: [],
          isError: false,
          isInfo: false,
          isSuccess: true,
        })
      );
    } catch (error) {
      dispatch(
        updateToastMessage({
          show: true,
          message: "ಪದ ಸಲಹೆ ವಿಫಲವಾಗಿದೆ.",
          additionalErrorMessages: [],
          isError: true,
          isInfo: false,
          isSuccess: false,
        })
      );
    }
  };

  return (
    <div className="no-data-found-wrapper">
      <img src={NoDataFoundImg} alt="no data found" />
      <div className="no-data-text">
        <div>
          ನೀವು ಹುಡುಕಿದ ಈ ಪದ ಸಧ್ಯಕ್ಕೆ ನಮ್ಮ ಪದನೆರಕೆಯಲ್ಲಿ ಇಲ್ಲ. ಈ ಕೆಳಗಿನವುಗಳಲ್ಲಿ
          ಒಂದನ್ನು ಆಯ್ಕೆ ಮಾಡಿಕೊಂಡು ಈ ಪದ ಸೇರಿಸಲು ನಮಗೆ ನೀವು ನೆರವಾಗಬಹುದು.
          <ol style={{ marginLeft: "20px" }}>
            <li key={1}>
              ಈ ಇಂಗ್ಲೀಶ್ ಪದಕ್ಕೆ ಸಾಟಿಯಾಗುವ ಕನ್ನಡ ಪದ ಆಗಲೇ ನಿಮಗೆ ಗೊತ್ತಿದ್ದರೆ ಅದನ್ನು
              ನಮಗೆ ಸೂಚಿಸಲು{" "}
              <span
                onClick={() => navigate("/add-new-suggestion")}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                ಇಲ್ಲಿ ಒತ್ತಿ
              </span>
            </li>
            <li key={2}>
              ಈ ಇಂಗ್ಲೀಶ್ ಪದಕ್ಕೆ ನಮ್ಮ ತಂಡವೇ ಪದ ಸೇರಿಸಲಿ ಅನ್ನುವಂತಿದ್ದರೆ{" "}
              <span
                onClick={addWordSuggestionToAddByAdmin}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                ಇಲ್ಲಿ ಒತ್ತಿ
              </span>
            </li>
          </ol>{" "}
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoDataFound);
