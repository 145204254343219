import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchResult: [],
  noResultFound: false,
  dataToEdit: null,
  editExisting: false,
  editNewSuggestion: false,
  suggestionGivenUserId: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },
    updateNoResultFoundStatus: (state, action) => {
      state.noResultFound = action.payload;
    },
    updateDataToEdit: (state, action) => {
      state.dataToEdit = action.payload;
    },
    updateEditExisting: (state, action) => {
      state.editExisting = action.payload;
    },
    updateEditNewSuggestion: (state, action) => {
      state.editNewSuggestion = action.payload;
    },
    updateSuggestionGivenUserId: (state, action) => {
      state.suggestionGivenUserId = action.payload;
    },
    clearDataToEdit: (state) => {
      state.dataToEdit = null;
      state.editExisting = false;
      state.editNewSuggestion = false;
      state.suggestionGivenUserId = "";
    },
  },
});

export const {
  updateSearchResult,
  updateNoResultFoundStatus,
  updateDataToEdit,
  updateEditExisting,
  updateEditNewSuggestion,
  updateSuggestionGivenUserId,
  clearDataToEdit,
} = searchSlice.actions;

export const selectSearchResult = (state) => state.search.searchResult;
export const selectNoResultFound = (state) => state.search.noResultFound;
export const selectDataToEdit = (state) => state.search.dataToEdit;
export const selectEditExisting = (state) => state.search.editExisting;
export const selectSuggestionGivenUserId = (state) =>
  state.search.suggestionGivenUserId;
export const selectEditNewSuggestion = (state) =>
  state.search.editNewSuggestion;

export default searchSlice.reducer;
