import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../slices/userSlice";
import searchSlice from "../slices/searchResultSlice";
import settingSlice from "../slices/settingsSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    search: searchSlice,
    setting: settingSlice,
  },
});
