import CryptoJS from "crypto-js";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  doc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

export const blockUnwantedInput = (value, allowLang) => {
  let newValue = value.split("");
  if (allowLang === "kannada") {
    const regex = /[^a-zA-Z0-9]+/g;
    if (!regex.test(newValue[newValue.length - 1])) {
      newValue.pop();
    }
  } else {
    const regex = /[^a-zA-Z ]+/g;
    if (regex.test(newValue[newValue.length - 1])) {
      newValue.pop();
    }
  }
  newValue = newValue.join("");

  return newValue;
};

export const blockUnwantedInputFromAnArray = (value, allowLang) => {
  let newValue = value;
  if (allowLang === "kannada") {
    const regex = /^[\u0C80-\u0CFF\s]*$/;
    if (!regex.test(newValue[newValue.length - 1])) {
      newValue.pop();
    }
  } else {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(newValue[newValue.length - 1])) {
      newValue.pop();
    }
  }

  return newValue;
};

export const findWhetherDataIsPresent = (data) => {
  let isDataPresent = false;

  if (data?.length > 0 && data?.[0] !== "") {
    isDataPresent = true;
  }

  return isDataPresent;
};

const SALT = process.env.REACT_APP_SALT_KEY;

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SALT).toString();
};

export const decryptData = (encryptedData) => {
  let bytes = CryptoJS.AES.decrypt(encryptedData, SALT);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const fetchDocumentsWithPagination = async (
  collectionName,
  orderByField,
  lastDoc,
  firstDoc,
  limitCount = 25,
  direction
) => {
  let q;
  if (direction === "next") {
    q = query(
      collection(db, collectionName),
      orderBy(orderByField),
      startAfter(lastDoc),
      limit(limitCount)
    );
  } else if (direction === "prev") {
    q = query(
      collection(db, collectionName),
      orderBy(orderByField),
      endBefore(firstDoc),
      limitToLast(limitCount)
    );
  } else {
    q = query(
      collection(db, collectionName),
      orderBy(orderByField),
      limit(limitCount)
    );
  }
  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs.map((doc) => doc.data());
  return {
    docs,
    firstDoc: querySnapshot.docs[0],
    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
  };
};

export const fetchDocumentsFromSubCollectionWithPagination = async (
  collectionName,
  userId,
  subCollectionName,
  orderByField,
  lastDoc,
  firstDoc,
  limitCount = 25,
  direction
) => {
  let q;
  if (direction === "next") {
    q = query(
      collection(doc(db, collectionName, userId), subCollectionName),
      orderBy(orderByField),
      startAfter(lastDoc),
      limit(limitCount)
    );
  } else if (direction === "prev") {
    q = query(
      collection(doc(db, collectionName, userId), subCollectionName),
      orderBy(orderByField),
      endBefore(firstDoc),
      limitToLast(limitCount)
    );
  } else {
    q = query(
      collection(doc(db, collectionName, userId), subCollectionName),
      orderBy(orderByField),
      limit(limitCount)
    );
  }
  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs.map((doc) => doc.data());
  return {
    docs,
    firstDoc: querySnapshot.docs[0],
    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
  };
};

const stringToColor = (string) => {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

export const findInputLanguage = (text) => {
  const languagePattern = /^[a-zA-Z\u0C00-\u0D7F\u1E00-\u1EFF]+$/; // Matches English and Kannada letters
  let language = "Kannada";
  if (languagePattern.test(text)) {
    if (text.match(/^[a-zA-Z]+$/)) {
      language = "English";
    }
  }
  if (!languagePattern.test(text)) {
    language = "Others";
  }
  return language;
};

export const isTheWordAlreadyBookMarked = (text, myBookmarks) => {
  return myBookmarks?.some((bookmark) => bookmark.word === text);
};

export const truncateText = (text) => {
  if (window.innerWidth < 1024) {
    if (text?.length > 15) {
      text = text.substring(0, 15) + "...";
    }
  } else {
    if (text?.length > 30) {
      text = text.substring(0, 30) + "...";
    }
  }
  return text;
};
