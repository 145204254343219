import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import {
  selectSuggestionsReceived,
  selectUserData,
  updateSuggestionsReceived,
} from "../slices/userSlice";
import { useNavigate } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import {
  updateDataToEdit,
  updateEditNewSuggestion,
  updateSuggestionGivenUserId,
} from "../slices/searchResultSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SuggestionsReceived = () => {
  const user = useSelector(selectUserData);
  const suggestionsReceived = useSelector(selectSuggestionsReceived);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (user.type === "admin" || user.type === "super-admin") {
      const q = query(
        collection(db, process.env.REACT_APP_SUGGESTIONS_RECEIVED_COLLECTION),
        limit(100)
      );
      const querySnapshot = await getDocs(q);
      dispatch(
        updateSuggestionsReceived(querySnapshot.docs.map((doc) => doc.data()))
      );
    }
  };

  const handleEditButtonClicked = (e, document) => {
    e.preventDefault();
    dispatch(updateDataToEdit(document.document));
    dispatch(updateSuggestionGivenUserId(document.userId));
    dispatch(updateEditNewSuggestion(true));
    navigate("/add-new-suggestion");
  };

  const handleRejectButtonClicked = async (e, document) => {
    e.preventDefault();
    const docId = document.document.uniqueId;
    const userId = document.userId;
    await deleteDoc(
      doc(db, process.env.REACT_APP_SUGGESTIONS_RECEIVED_COLLECTION, docId)
    );

    await updateDoc(
      doc(
        db,
        process.env.REACT_APP_USERS_COLLECTION,
        userId,
        process.env.REACT_APP_SUGGESTIONS_GIVEN_COLLECTION,
        docId
      ),
      {
        isSuggestionAccepted: false,
      }
    );
    fetchData();
  };

  return (
    <div className="suggestions-page-wrapper">
      <div className="suggestions-page-section">
        <div
          className="table-container"
          style={{ height: "fit-content", width: "100%" }}
        >
          {suggestionsReceived.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{ minWidth: 150, fontSize: 20, fontWeight: 600 }}
                    >
                      ಆಂಗ್ಲ ಪದ
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ minWidth: 150, fontSize: 20, fontWeight: 600 }}
                    >
                      ಕನ್ನಡ ಪದ
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        minWidth: 300,
                        fontSize: 20,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      ತೀರ್ಮಾನ
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ minWidth: 150, fontSize: 20, fontWeight: 600 }}
                    >
                      ಸೂಚಿಸಿದವರ ಹೆಸರು
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ minWidth: 250, fontSize: 20, fontWeight: 600 }}
                    >
                      ಸೂಚಿಸಿದವರ ಮಿಂಚೆ
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suggestionsReceived.map((data, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell align="left" scope="row">
                        {data.document.searchEnglishWord[0]}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="left">
                        {data.document.searchKannadaWord[0]}
                      </StyledTableCell>
                      <StyledTableCell align="left" scope="row">
                        <Button
                          onClick={(e) => handleEditButtonClicked(e, data)}
                          sx={{
                            color: "green",
                            marginLeft: 5,
                            fontSize: 20,
                            fontWeight: 600,
                          }}
                        >
                          <ModeEditOutlinedIcon sx={{ marginRight: 1 }} />
                          ಒಪ್ಪಿ
                        </Button>
                        <Button
                          onClick={(e) => handleRejectButtonClicked(e, data)}
                          sx={{
                            color: "red",
                            marginLeft: 5,
                            fontSize: 20,
                            fontWeight: 600,
                          }}
                        >
                          <DeleteOutlinedIcon sx={{ marginRight: 1 }} />
                          ಬೇಡ
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="left" scope="row">
                        {data.userName}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        scope="row"
                        className="word-cell"
                      >
                        <a href={`mailto:${data.userEmail}`} target="_blank">
                          {data.userEmail}
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div
              style={{ height: "fit-content", width: "100%", display: "flex" }}
            >
              <span
                style={{
                  width: "100%",
                  height: "fit-content",
                  textAlign: "center",
                }}
              >
                ತೋರಿಸಲು ಯಾವುದೇ ಸಲಹೆಗಳಿಲ್ಲ
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuggestionsReceived;
