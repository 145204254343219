import React, { useState } from "react";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  listAll,
} from "firebase/storage";
import { deleteObject } from "firebase/storage";
import { storage } from "../firebase/firebaseConfig";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Snackbar,
  CircularProgress,
  Box,
  Chip,
  Autocomplete,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateToastMessage } from "../slices/settingsSlice";
import { truncateText } from "../utils/utils";

const ImageBulkUpload = () => {
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const dispatch = useDispatch();

  const handleUpload = (e) => {
    const imagesArray = Array.from(e?.target?.files);
    let isError = false;
    imagesArray.forEach((image) => {
      const fileSizeInMB = image.size / (1024 * 1024);
      if (fileSizeInMB > 1) {
        dispatch(
          updateToastMessage({
            show: true,
            message:
              "ಅಪ್‌ಲೋಡ್ ಮಾಡಿದ ಫೈಲ್ ಗಾತ್ರವು 1Mb ಗಿಂತ ಹೆಚ್ಚಿದೆ, ದಯವಿಟ್ಟು 1mb ಗಿಂತ ಕಡಿಮೆ ಗಾತ್ರದ ಫೈಲ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ.",
            additionalErrorMessages: [],
            isError: true,
            isInfo: false,
            isSuccess: false,
          })
        );
        isError = true;
      }
    });
    if (!isError) {
      setLoading(true);
      imagesArray.forEach((image) => {
        const storageRef = ref(storage, `tilipada-images/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle the upload task.
          },
          (error) => {
            console.error(error);
          },
          () => {
            setLoading(false);
          }
        );
      });
    }
  };

  const handleOpen = (url) => {
    setSelectedImage(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedImage);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const imagesListLoad = async (e) => {
    e.preventDefault();
    const storageFunction = getStorage();
    const imageRefs = ref(storageFunction, "tilipada-images");

    const res = await listAll(imageRefs);

    const urls = await Promise.all(
      res.items.map((item) => getDownloadURL(item))
    );
    const imageData = [];
    urls.forEach((url) => {
      imageData.push({
        name: decodeURIComponent(url.split("%2F").pop().split("?")[0]),
        url,
      });
    });

    setImages(imageData);
  };

  const addSearchedImagesToList = (text) => {
    const selectedImage = images?.filter((image) => image.name === text);
    if (selectedImage?.length > 0) {
      setSelectedImage(selectedImage?.[0]?.url || "");
      setOpen(true);
    }
  };

  const deleteImage = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      const storageFunction = getStorage();
      const imageRef = ref(storageFunction, selectedImage);
      try {
        await deleteObject(imageRef);
        setSelectedImage(null);
        setOpen(false);
        imagesListLoad(e);
        dispatch(
          updateToastMessage({
            show: true,
            message: "ಚಿತ್ರವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ.",
            additionalErrorMessages: [],
            isError: false,
            isInfo: false,
            isSuccess: true,
          })
        );
      } catch (error) {
        dispatch(
          updateToastMessage({
            show: true,
            message: "ಚಿತ್ರ ಅಳಿಸುವಿಕೆ ವಿಫಲವಾಗಿದೆ.",
            additionalErrorMessages: [],
            isError: true,
            isInfo: false,
            isSuccess: false,
          })
        );
      }
    }
  };

  return (
    <div className="suggestions-page-wrapper">
      <div className="suggestions-page-section">
        <>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleUpload}
          />
          <label className="upload-button-label" htmlFor="raised-button-file">
            <Button
              variant="outlined"
              component="span"
              color="secondary"
              style={{
                border: "1px solid rgba(190, 182, 182, 0.922)",
                marginLeft: "10px",
                marginTop: "10px",
                fontSize: 16,
              }}
            >
              ಚಿತ್ರಗಳನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ
            </Button>
          </label>
        </>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              variant="outlined"
              component="span"
              style={{
                border: "1px solid rgba(190, 182, 182, 0.922)",
                marginLeft: "10px",
                marginTop: "10px",
                fontSize: 16,
              }}
              onClick={imagesListLoad}
            >
              ಚಿತ್ರಗಳ ಪಟ್ಟಿಯನ್ನು ಲೋಡ್ ಮಾಡಿ
            </Button>

            {images.length > 0 && (
              <Stack
                spacing={3}
                sx={{ width: "90%", marginTop: 2, marginBottom: 2 }}
              >
                <Autocomplete
                  id="tags-standard"
                  options={images}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="ಹುಡುಕಿ"
                      placeholder="ಫೋಟೋ ಹೆಸರನ್ನು ಹುಡುಕಿ"
                    />
                  )}
                  onClose={(e) => addSearchedImagesToList(e.target.textContent)}
                />
              </Stack>
            )}

            {images.length > 0 && (
              <h4
                style={{ textAlign: "left", width: "90%", marginTop: "10px" }}
              >
                ಚಿತ್ರಗಳ ಪಟ್ಟಿ:
              </h4>
            )}

            <Box
              display="flex"
              flexWrap="wrap"
              gap={1}
              margin={"auto"}
              marginTop={2}
              alignItems={"flex-start"}
              justifyContent={"center"}
              height={"fit-content"}
              maxHeight={150}
            >
              {images.map((image, index) => (
                <Chip
                  label={truncateText(image.name)}
                  key={index}
                  onClick={() => handleOpen(image.url)}
                />
              ))}
            </Box>
          </>
        )}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>ಚಿತ್ರದ ಕೊಂಡಿ</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "300px",
              height: "fit-content",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={selectedImage || ""}
              alt=""
              style={{
                width: "250px",
                height: "250px",
                margin: "auto",
                marginBottom: "10px",
                objectFit: "contain",
              }}
            />
            <TextField
              fullWidth
              value={selectedImage || ""}
              InputProps={{ readOnly: true }}
              onClick={handleCopy}
              sx={{
                cursor: "pointer",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "180px",
                maxWidth: "180px",
              }}
            />
            <Button
              variant="outlined"
              component="span"
              style={{
                border: "1px solid rgba(190, 182, 182, 0.922)",
                marginLeft: "10px",
                marginTop: "10px",
                fontSize: 16,
              }}
              onClick={(e) => deleteImage(e)}
            >
              ಚಿತ್ರವನ್ನು ಅಳಿಸಿ
            </Button>
          </DialogContent>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message="ಚಿತ್ರದ ಕೊಂಡಿ ಅನ್ನು ನಕಲಿಸಲಾಗಿದೆ!"
        />
      </div>
    </div>
  );
};

export default ImageBulkUpload;
