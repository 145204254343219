import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, db, googleProvider } from "../firebase/firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import "./LoginModal.scss";
import {
  updateApiAndCSRFHashKey,
  updateUserDetails,
} from "../slices/userSlice";
import { encryptData } from "../utils/utils";
import { updateToastMessage } from "../slices/settingsSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  maxWidth: "600px",
  bgcolor: "#ffffff",
  border: "1px solid lightGray",
  boxShadow: 24,
  outline: "none",
  p: 4,
  display: "flex",
  flexDirection: "column",
};

const RequiredText = ({ toggleLoginModal }) => (
  <span>
    ನಾನು ಎಲ್ಲಾ{" "}
    <Link
      className="required-text-link"
      to={"/terms-and-conditions"}
      onClick={toggleLoginModal}
    >
      ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು
    </Link>{" "}
    ಓದಿದ್ದೇನೆ ಮತ್ತು ಇದಕ್ಕೆ ನನ್ನ ಒಪ್ಪಿಗೆ ಇದೆ. *
  </span>
);

const LoginPage = ({ loginModalOpen, toggleLoginModal }) => {
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const redirectedFrom = location.state?.from || "/";

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const onLoginButtonClick = async (e) => {
    e.preventDefault();

    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const q = query(
        collection(db, process.env.REACT_APP_USERS_COLLECTION),
        where("uid", "==", user.uid)
      );
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        const userApiKey = uuidv4();
        const docRef = doc(
          db,
          process.env.REACT_APP_USERS_COLLECTION,
          user.uid
        );
        await setDoc(docRef, {
          uid: user.uid,
          name: user.displayName,
          photoURL: user.photoURL,
          emailId: user.email,
          mobileNumber: user.phoneNumber,
          type: "user",
          apiKey: userApiKey,
          myBookMarks: [],
        });

        dispatch(
          updateUserDetails({
            userId: user.uid,
            name: user.displayName,
            photoURL: user.photoURL,
            emailId: user.email,
            mobileNumber: user.phoneNumber,
            type: "user",
            apiKey: userApiKey,
            myBookMarks: [],
          })
        );
      } else {
        const docRef = doc(
          db,
          process.env.REACT_APP_USERS_COLLECTION,
          user.uid
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          dispatch(
            updateToastMessage({
              show: true,
              message: "ನೀವು ಯಶಸ್ವಿಯಾಗಿ ಒಳಬಂದಿದ್ದೀರಿ.",
              additionalErrorMessages: [],
              isError: false,
              isInfo: false,
              isSuccess: true,
            })
          );
          dispatch(
            updateUserDetails({
              userId: docSnap.data().uid,
              name: docSnap.data().name,
              photoURL: docSnap.data().photoURL,
              emailId: docSnap.data().emailId,
              mobileNumber: user.mobileNumber,
              type: docSnap.data().type,
              apiKey: docSnap.data().apiKey,
              myBookMarks: docSnap.data().myBookMarks,
            })
          );
        }
      }
      toggleLoginModal();
    } catch (error) {
      dispatch(
        updateToastMessage({
          show: true,
          message:
            "ನಿಮ್ಮನ್ನು ಲಾಗ್ ಇನ್ ಮಾಡುವಾಗ ದೋಷ ಕಂಡುಬಂದಿದೆ, ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ.",
          additionalErrorMessages: [],
          isError: true,
          isInfo: false,
          isSuccess: false,
        })
      );
    }
    navigate(redirectedFrom, { replace: true });
  };

  return (
    <div className="login-wrapper">
      <Modal
        open={loginModalOpen}
        onClose={toggleLoginModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="login-header">
            ಒಳ ಬನ್ನಿ / ಸೇರಿಕೊಳ್ಳಿ
          </Typography>

          <br />

          {/* <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={<RequiredText toggleLoginModal={toggleLoginModal} />}
            className="required-text"
          /> */}

          <button
            className="login-button"
            // disabled={!checked}
            onClick={onLoginButtonClick}
          >
            <GoogleIcon className="google-icon" />
            <span>ಒಳ ಬನ್ನಿ</span>
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default React.memo(LoginPage);
