import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoadingStatus } from "../slices/settingsSlice";
import {
  selectNoResultFound,
  selectSearchResult,
  updateSearchResult,
} from "../slices/searchResultSlice";
import Search from "../components/Search";
import Spinner from "./Spinner";
import NoDataFound from "../components/NoDataFound";
import SearchResult from "../components/SearchResults";
import "./HomePage.scss";

const HomePage = ({
  selectedWordAndLang,
  isBackSpacePressed,
  setSelectedWordAndLang,
}) => {
  const loadingStatus = useSelector(selectLoadingStatus);
  const isNoResultFound = useSelector(selectNoResultFound);
  const searchResults = useSelector(selectSearchResult);

  const [initialSearchDone, setInitialSearchDone] = useState(false);
  const [isEnglish, setIsEnglish] = useState(true);
  const [searchedWord, setSearchedWord] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const searchContainer =
      document.getElementsByClassName("search-container")[0];
    if (!initialSearchDone) {
      searchContainer.style.marginTop = "30vh";
      searchContainer.style.transition = "1s";
      dispatch(updateSearchResult([]));
    } else {
      searchContainer.style.marginTop = "50px";
      searchContainer.style.transition = "1s";
    }
  }, [initialSearchDone]);

  useEffect(() => {
    if (selectedWordAndLang.word !== "") {
      setInitialSearchDone(true);
      setIsEnglish(!selectedWordAndLang.isKannada);
    }
  }, [selectedWordAndLang]);

  return (
    <div className="home-wrapper">
      <Search
        setInitialSearchDone={setInitialSearchDone}
        initialSearchDone={initialSearchDone}
        isEnglish={isEnglish}
        setIsEnglish={setIsEnglish}
        selectedWordAndLang={selectedWordAndLang}
        isBackSpacePressed={isBackSpacePressed}
      />
      {loadingStatus ? (
        <Spinner />
      ) : (
        <>
          {isNoResultFound ? (
            <NoDataFound isEnglish={isEnglish} />
          ) : (
            <>
              {searchResults?.hits?.length > 0 ? (
                <SearchResult
                  searchResults={searchResults}
                  isEnglish={isEnglish}
                  setSelectedWordAndLang={setSelectedWordAndLang}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(HomePage);
