import React from "react";
import "./TermsAndConditionsPage.scss";

const TermsAndConditionsPage = () => {
  return (
    <div className="tnc-wrapper">
      <div className="tnc-section">Terms And Conditions Page</div>
    </div>
  );
};

export default TermsAndConditionsPage;
