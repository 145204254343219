import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUserData } from "../slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/MenuRounded";
import "./Header.scss";
import axios from "axios";
import { updateToastMessage } from "../slices/settingsSlice";
import ReactGA from "react-ga4";
// import logo from "../assets/tilipada-logo.jpg";
import tilipadaLogoVersion1 from "../assets/Tilipada logo 1.svg";
// import tilipadaLogoVersion2 from "../assets/Tilipada logo 2.svg";

const Header = ({
  tabName,
  open,
  handleTab,
  handleSideMenuToggle,
  toggleLoginModal,
}) => {
  const [showIcon, setShowIcon] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(selectUserData);
  const dispatch = useDispatch();

  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
    const searchData = location.search?.split("=");
    const query1 = searchData?.[0].substring(1, searchData?.[0].length);
    if (query1 !== "word") {
      navigate(location.pathname);
    }
  }, []);

  const path = window.location.pathname;
  useEffect(() => {
    let tab =
      path === "/"
        ? "home"
        : path === "/glossary"
        ? "glossary"
        : path === "/add-new-suggestion"
        ? "suggestion"
        : path === "/about-us"
        ? "about"
        : "user";
    handleTab(path, tab, navigate, false);
  }, [path]);

  // const sharePage = (e) => {
  //   e.preventDefault();
  // };

  const downloadDictionary = async (e) => {
    e.preventDefault();
    const isDownloadConfirmed = window.confirm(
      "ತಿಳಿಪದ ಪದನೆರಕೆಯಲ್ಲಿರುವ ಎಲ್ಲಾ ಪದಗಳನ್ನು xls ರೂಪದ ಕಡತದಲ್ಲಿ ಇಳಿಸಿಕೊಳ್ಳಲು ಇಲ್ಲಿ ಒತ್ತಿ"
    );
    if (!disableDownload && isDownloadConfirmed) {
      setDisableDownload(true);
      const date = new Date();
      const timestamp = `${date.getFullYear()}_${String(
        date.getMonth() + 1
      ).padStart(2, "0")}_${String(date.getDate()).padStart(2, "0")}_${String(
        date.getHours()
      ).padStart(2, "0")}_${String(date.getMinutes()).padStart(
        2,
        "0"
      )}_${String(date.getSeconds()).padStart(2, "0")}`;
      const fileName = "ಅರಿಮೆ ತಿಳಿಪದ ಪದನೆರಕೆ " + timestamp + ".xlsx";
      if (user.type === "user" || user.type === "") {
        axios
          .get(`/backend/arime/dictionary/public/downloadDictionaryData`, {
            /*  proxy: {
            protocol: process.env.REACT_APP_BACKEND_PROTOCOL,
            host: process.env.REACT_APP_BACKEND_HOST,
            port: process.env.REACT_APP_BACKEND_PORT,
          }, */
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            dispatch(
              updateToastMessage({
                show: true,
                message:
                  "ತಿಳಿಪದ ಪದನೆರೆಕೆಯ ಕಡತವನ್ನು ನಿಮ್ಮ ಕಂಪ್ತ್ಯೂಟರ್ ಗೆ ಇಳಿಸಲಾಗಿದೆ.",
                additionalErrorMessages: [],
                isError: false,
                isInfo: false,
                isSuccess: true,
              })
            );
            // Track the download event with ReactGA
            ReactGA.event({
              category: "Download",
              action: "Performed download operation",
              label: `User ${
                user?.name ? user?.name(user?.emailId) : "Unknown"
              } downloaded file: ${fileName}`,
            });
            setDisableDownload(false);
          })
          .catch((error) => {
            let message = "";
            let additionalErrorMessages = [];
            if (error?.response?.data?.message?.length > 0) {
              message = error?.response?.data?.message;
              if (error?.response?.data?.additionalErrorInfo?.length > 0) {
                error?.response?.data?.additionalErrorInfo?.forEach((str) => {
                  additionalErrorMessages.push(str);
                });
              }
            } else {
              message = error?.message;
            }
            if (error?.response?.status && error?.response?.status > 299) {
              dispatch(
                updateToastMessage({
                  show: true,
                  message:
                    "ತೊಂದರೆ- ತಿಳಿಪದ ಪದನೆರೆಕೆಯ ಕಡತವನ್ನು ನಿಮ್ಮ ಕಂಪ್ತ್ಯೂಟರ್ ಗೆ ಇಳಿಸಲಾಗಲಿಲ್ಲ.",
                  additionalErrorMessages,
                  isError: true,
                  isInfo: false,
                  isSuccess: false,
                })
              );
            }
            setDisableDownload(false);
          });
      } else {
        axios
          .get(`/backend/arime/dictionary/downloadBulkUploadedExcel`, {
            /*  proxy: {
            protocol: process.env.REACT_APP_BACKEND_PROTOCOL,
            host: process.env.REACT_APP_BACKEND_HOST,
            port: process.env.REACT_APP_BACKEND_PORT,
          }, */
            headers: {
              "X-API-KEY": user.apiKey,
            },
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            dispatch(
              updateToastMessage({
                show: true,
                message:
                  "ತಿಳಿಪದ ಪದನೆರೆಕೆಯ ಕಡತವನ್ನು ನಿಮ್ಮ ಕಂಪ್ತ್ಯೂಟರ್ ಗೆ ಇಳಿಸಲಾಗಿದೆ.",
                additionalErrorMessages: [],
                isError: false,
                isInfo: false,
                isSuccess: true,
              })
            );
            // Track the download event with ReactGA
            ReactGA.event({
              category: "Download",
              action: "Performed download operation",
              label: `Admin ${
                user?.name ? user?.name(user?.emailId) : "Unknown"
              } downloaded file: ${fileName}`,
            });
            setDisableDownload(false);
          })
          .catch((error) => {
            let message = "";
            let additionalErrorMessages = [];
            if (error?.response?.data?.message?.length > 0) {
              message = error?.response?.data?.message;
              if (error?.response?.data?.additionalErrorInfo?.length > 0) {
                error?.response?.data?.additionalErrorInfo?.forEach((str) => {
                  additionalErrorMessages.push(str);
                });
              }
            } else {
              message = error?.message;
            }
            if (error?.response?.status && error?.response?.status > 299) {
              dispatch(
                updateToastMessage({
                  show: true,
                  message:
                    "ತೊಂದರೆ- ತಿಳಿಪದ ಪದನೆರೆಕೆಯ ಕಡತವನ್ನು ನಿಮ್ಮ ಕಂಪ್ತ್ಯೂಟರ್ ಗೆ ಇಳಿಸಲಾಗಲಿಲ್ಲ.",
                  additionalErrorMessages,
                  isError: true,
                  isInfo: false,
                  isSuccess: false,
                })
              );
            }
            setDisableDownload(false);
          });
      }
    }
  };

  const handleMobileNavigation = (path) => {
    navigate(path);
    handleSideMenuToggle();
  };

  return (
    <nav className="header-wrapper">
      <div className="logo-section">
        <div className="logo-text">
          <div onClick={() => handleTab("/", "home", navigate)}>
            <img alt="tilipada logo" src={tilipadaLogoVersion1} />
          </div>
        </div>
      </div>
      {!showIcon ? (
        <div className="nav-section">
          <span
            className={`header-item ${tabName === "home" && "active-tab"}`}
            onClick={() => handleTab("/", "home", navigate)}
          >
            ಪದ ಹುಡುಕಿ
          </span>
          <span
            className={`header-item ${tabName === "glossary" && "active-tab"}`}
            onClick={() => navigate("/glossary")}
          >
            ಪದಸರಣಿ
          </span>
          <span
            className={`header-item ${
              tabName === "suggestion" && "active-tab"
            }`}
            onClick={() => navigate("/add-new-suggestion")}
          >
            ಪದ ಸೂಚಿಸಿ
          </span>
          <span
            className={`header-item ${tabName === "about" && "active-tab"}`}
            onClick={() => navigate("/about-us")}
          >
            ತಿಳಿಪದ ಕುರಿತು
          </span>
          <span className={`header-item ${tabName === "user" && "active-tab"}`}>
            {!user?.userId ? (
              <span onClick={() => toggleLoginModal()}>ಒಳ ಬನ್ನಿ</span>
            ) : (
              <span onClick={() => navigate(`/user/${user?.userId}`)}>
                ನನ್ನ ಪುಟ
              </span>
            )}
          </span>
          <button
            className={`header-item ${tabName === "download" && "active-tab"}`}
            title="ಪದನೆರಕೆ ಇಳಿಸಿಕೊಳ್ಳಿ"
            onClick={downloadDictionary}
            disabled={disableDownload}
            style={{ color: `${disableDownload ? "darkgrey" : "black"}` }}
          >
            ಇಳಿಸಿಕೊಳ್ಳಿ
          </button>
          {/* <span className="header-item" onClick={sharePage}>
            ಹಂಚಿಕೊಳ್ಳಿ
          </span> */}
        </div>
      ) : (
        <div>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleSideMenuToggle}
          >
            <MenuIcon className="burger-icon" />
          </IconButton>
          <Drawer
            anchor="right"
            open={open}
            onClose={handleSideMenuToggle}
            className="drawer-wrapper"
          >
            <List>
              <ListItem onClick={() => handleMobileNavigation("/")}>
                <ListItemText primary="ಪದ ಹುಡುಕಿ" className="drawer-option" />
              </ListItem>
              <ListItem onClick={() => handleMobileNavigation("/glossary")}>
                <ListItemText primary="ಪದಸರಣಿ" className="drawer-option" />
              </ListItem>
              <ListItem
                onClick={() => handleMobileNavigation("/add-new-suggestion")}
              >
                <ListItemText primary="ಪದ ಸೂಚಿಸಿ" className="drawer-option" />
              </ListItem>
              <ListItem onClick={() => handleMobileNavigation("/about-us")}>
                <ListItemText
                  primary="ತಿಳಿಪದ ಕುರಿತು"
                  className="drawer-option"
                />
              </ListItem>
              <ListItem>
                {!user?.userId ? (
                  <ListItemText
                    primary="ಒಳ ಬನ್ನಿ"
                    onClick={() => toggleLoginModal()}
                    className="drawer-option"
                  />
                ) : (
                  <ListItemText
                    primary="ನನ್ನ ಪುಟ"
                    onClick={() =>
                      handleMobileNavigation(`/user/${user?.userId}`)
                    }
                    className="drawer-option"
                  />
                )}
              </ListItem>
              <ListItem disabled={disableDownload} onClick={downloadDictionary}>
                <ListItemText primary="ಇಳಿಸಿಕೊಳ್ಳಿ" className="drawer-option" />
              </ListItem>
              {/* <ListItem onClick={sharePage}>
                <ListItemText primary="ಹಂಚಿಕೊಳ್ಳಿ" className="drawer-option" />
              </ListItem> */}
            </List>
          </Drawer>
        </div>
      )}
    </nav>
  );
};

export default React.memo(Header);
