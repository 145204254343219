import React from "react";

const SearchResultShowLinksAndImages = ({
  data,
  isCustomLinkUrls,
  isVideoUrls,
  isImageUrls,
}) => {
  const createAnchorText = (link) => {
    return link?.split("/")[2]?.split(".")[1];
  };

  return (
    <>
      {(isCustomLinkUrls || isVideoUrls) && (
        <>
          <h2 className="search-result-section-headers">
            {isCustomLinkUrls ? "ಇತರ ಜಾಲತಾಣ ಕೊoಡಿಗಳು" : "ವೀಡಿಯೊ ಕೊoಡಿಗಳು"}:
          </h2>
          <div className="searched-result-description">
            <ol>
              {data?.map((link, i) => (
                <li key={i}>
                  <a href={link} target="_blank">
                    {createAnchorText(link)}
                  </a>
                </li>
              ))}
            </ol>
          </div>
        </>
      )}
      {isImageUrls && (
        <>
          <h2 className="search-result-section-headers">ಚಿತ್ರಗಳು:</h2>
          <div className="image-section">
            {data.map((imageUrl, i) => (
              <img src={imageUrl} alt="" key={i} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SearchResultShowLinksAndImages;
