import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  updateNoResultFoundStatus,
  updateSearchResult,
} from "../slices/searchResultSlice";
import { updateLoadingStatus } from "../slices/settingsSlice";
import { FormControlLabel, Switch } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { blockUnwantedInput, findInputLanguage } from "../utils/utils";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Search = ({
  setInitialSearchDone,
  initialSearchDone,
  isEnglish,
  setIsEnglish,
  selectedWordAndLang,
  isBackSpacePressed,
}) => {
  const [searchedValue, setSearchedValue] = useState(selectedWordAndLang.word);
  const [searchedValueRef, setSearchedValueRef] = useState(
    selectedWordAndLang.word
  );
  const [selectedText, setSelectedText] = useState("");
  const [wordSuggestions, setWordSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef(null);
  const searchContainerRef = useRef(null);

  const dispatch = useDispatch();

  const backendAPI = `${process.env.REACT_APP_TYPESENSE_URL}/collections/${process.env.REACT_APP_DICTIONARY_COLLECTION_NAME}/documents/`;
  const typeSenseAPIKEY = process.env.REACT_APP_TYPESENSE_API_KEY;
  const googleInputToolsURL = process.env.REACT_APP_GOOGLE_INPUT_TOOLS_URL;

  useEffect(() => {
    if (isBackSpacePressed) {
      clearSuggestionsIfInputIsEmpty();
    }
  }, [isBackSpacePressed]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (selectedWordAndLang.word !== "") {
      fetchData(selectedWordAndLang.isKannada, selectedWordAndLang.word);
      if (!selectedWordAndLang.isKannada) {
        setSearchedValue(selectedWordAndLang.word);
      }
    } else {
      setSearchedValue("");
      setSearchedValueRef("");
    }
    setWordSuggestions([]);
  }, [selectedWordAndLang]);

  useEffect(() => {
    window?.addEventListener("select", (e) => {
      addToSelectedText(e);
    });

    return () => {
      window?.removeEventListener("select", (e) => {
        addToSelectedText(e);
      });
    };
  });

  const addToSelectedText = (e) => {
    const input = e.target;
    const selectedText = input.value.substring(
      input.selectionStart,
      input.selectionEnd
    );
    setSelectedText(selectedText);
  };

  const clearSuggestionsIfInputIsEmpty = () => {
    if (
      inputRef?.current?.value === "" ||
      inputRef?.current?.value?.length <= 1 ||
      searchedValueRef?.length <= 1
    ) {
      setWordSuggestions([]);
      dispatch(updateLoadingStatus(false));
      dispatch(updateNoResultFoundStatus(false));
      dispatch(updateSearchResult([]));
      setSearchedValue("");
      setSearchedValueRef("");
      searchContainerRef.current.style.marginTop = "30vh";
      searchContainerRef.current.style.transition = "1s";
    } else if (!isEnglish && searchedValueRef?.length === selectedText.length) {
      setWordSuggestions([]);
      dispatch(updateLoadingStatus(false));
      dispatch(updateNoResultFoundStatus(false));
      dispatch(updateSearchResult([]));
      setSearchedValue("");
      setSearchedValueRef("");
      setSelectedText("");
      searchContainerRef.current.style.marginTop = "30vh";
      searchContainerRef.current.style.transition = "1s";
    }
  };

  const focusOnSearch = (e) => {
    e.preventDefault();
    inputRef?.current?.focus();
  };

  const switchOnChangeHandler = (e) => {
    setIsEnglish(e.target.checked);
    inputRef?.current?.focus();
    setSearchedValue("");
    setSearchedValueRef("");
    setSelectedText("");
    setWordSuggestions([]);
    dispatch(updateNoResultFoundStatus(false));
    dispatch(updateSearchResult([]));
    dispatch(updateLoadingStatus(false));
    searchContainerRef.current.style.marginTop = "30vh";
    searchContainerRef.current.style.transition = "1s";
  };

  const checkIsEnglish = (text) => {
    const english = /^[A-Za-z0-9 ]*$/;
    return english.test(text);
  };

  const onPasteHandler = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    console.log(isEnglish, pastedData, checkIsEnglish(pastedData));
    if (isEnglish && checkIsEnglish(pastedData)) {
      e.target.value = pastedData;
      onChangeHandler(e);
    } else if (!isEnglish) {
      e.target.value = pastedData;
      onChangeHandler(e);
    } else {
      return;
    }
  };

  const onChangeHandler = async (e) => {
    e.preventDefault();
    let searchValue = e.target.value;
    if (e.target.value.length === 1 && e.target.value.slice(-1) === " ") {
      searchValue = e.target.value.slice(0, -1);
    }
    if (searchValue) {
      if (initialSearchDone) {
        setInitialSearchDone(false);
        dispatch(updateSearchResult([]));
        dispatch(updateLoadingStatus(false));
        dispatch(updateNoResultFoundStatus(false));
      }
    } else {
      if (!initialSearchDone) {
        setInitialSearchDone(true);
      }
    }

    if (!isEnglish) {
      const language = findInputLanguage(searchValue);
      if (language === "Others") {
        return;
      } else if (language === "English") {
        searchValue = blockUnwantedInput(searchValue, "Kannada");
      }
      if (searchValue) {
        setSearchedValue(searchValue);
        const kannadaSearchValue = await axios.get(
          `${googleInputToolsURL}/request?text=${searchValue}&itc=kn-t-i0-und&num=5&cp=0&cs=1&ie=utf-8&oe=utf-8&app=arime`
        );
        let kannadaSearchedWord = "";
        if (kannadaSearchValue.status === 200) {
          kannadaSearchedWord = kannadaSearchValue?.data?.[1]?.[0]?.[1]?.[0];
          setSearchedValueRef(kannadaSearchedWord);
        }
        const suggestions = await axios.get(
          `${backendAPI}search?q=${kannadaSearchedWord}&query_by=searchKannadaWord&group_by=searchKannadaWord&group_limit=1&per_page=25&page=1`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-TYPESENSE-API-KEY": typeSenseAPIKEY,
            },
          }
        );
        const searchSuggestions = [];
        suggestions?.data?.grouped_hits?.map((searchResult) => {
          searchSuggestions.push(searchResult.group_key[0][0]);
        });
        setWordSuggestions(searchSuggestions);
      }
    } else {
      const searchedEnglishText = blockUnwantedInput(searchValue, "English");
      if (searchedEnglishText) {
        setSearchedValue(searchedEnglishText);
        const suggestions = await axios.get(
          `${backendAPI}search?q=${searchedEnglishText}&query_by=searchEnglishWord&group_by=searchEnglishWord&group_limit=1&per_page=25&page=1`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-TYPESENSE-API-KEY": typeSenseAPIKEY,
            },
          }
        );
        const searchSuggestions = [];
        suggestions?.data?.grouped_hits?.map((searchResult) => {
          searchSuggestions.push(searchResult.group_key[0][0]);
        });
        setWordSuggestions(searchSuggestions);
      }
    }
    dispatch(updateNoResultFoundStatus(false));
    searchContainerRef.current.style.marginTop = "50px";
    searchContainerRef.current.style.transition = "1s";
  };

  const onSuggestionSelectionHandler = (e) => {
    e.preventDefault();
    dispatch(updateLoadingStatus(true));
    dispatch(updateNoResultFoundStatus(false));
    setSearchedValue(e.target.innerText);
    setSearchedValueRef(e.target.innerText);
    setWordSuggestions([]);
    inputRef?.current?.focus();
    dispatch(updateSearchResult([]));
    if (!isEnglish) {
      fetchData(!isEnglish, e.target.innerText);
    } else {
      fetchData(!isEnglish, e.target.innerText);
    }
  };

  const fetchData = async (isKannada, searchWord) => {
    const queryBy = !isKannada ? "searchEnglishWord" : "searchKannadaWord";
    const searchQuery = !isKannada
      ? searchWord
        ? searchWord
        : searchedValue?.trimEnd()
      : searchWord
      ? searchWord
      : searchedValueRef;
    if (!searchQuery) {
      return;
    }
    const data = await axios.get(
      `${backendAPI}search?q=${searchQuery}&query_by=${queryBy}&filter_by=${queryBy}:=${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-TYPESENSE-API-KEY": typeSenseAPIKEY,
        },
      }
    );
    if (data?.status === 200) {
      if (data?.data?.hits?.length > 0) {
        if (selectedWordAndLang.isKannada) {
          setSearchedValue(
            data.data.hits[0].document.searchEnglishWord[0] || ""
          );
          setSearchedValueRef(
            data.data.hits[0].document.searchKannadaWord[0] || ""
          );
        } else {
          setSearchedValue(
            data.data.hits[0].document.searchEnglishWord[0] || ""
          );
        }
        dispatch(updateSearchResult(data.data));
        setWordSuggestions([]);
      } else {
        dispatch(updateNoResultFoundStatus(true));
      }
      dispatch(updateLoadingStatus(false));
    } else {
      dispatch(updateNoResultFoundStatus(true));
      dispatch(updateSearchResult([]));
      dispatch(updateLoadingStatus(false));
    }
  };

  const onKeyDownOrClickHandler = async (e) => {
    e.preventDefault();
    if (searchedValue?.trimEnd() !== "") {
      dispatch(updateLoadingStatus(true));
      dispatch(updateNoResultFoundStatus(false));
      setWordSuggestions([]);
      dispatch(updateSearchResult([]));
      fetchData(!isEnglish);
    }
  };

  const clearHandler = (e) => {
    e.preventDefault();
    setWordSuggestions([]);
    dispatch(updateLoadingStatus(false));
    dispatch(updateNoResultFoundStatus(false));
    dispatch(updateSearchResult([]));
    setSearchedValue("");
    setSearchedValueRef("");
    setSelectedText("");
    searchContainerRef.current.style.marginTop = "30vh";
    searchContainerRef.current.style.transition = "1s";
    inputRef.current.focus();
  };

  return (
    <>
      <div className="search-container" ref={searchContainerRef}>
        <SearchRoundedIcon onClick={focusOnSearch} />

        <form onSubmit={onKeyDownOrClickHandler}>
          <input
            type="text"
            ref={inputRef}
            value={searchedValue}
            onChange={onChangeHandler}
            onPaste={onPasteHandler}
            placeholder={
              isEnglish ? "Search English word." : "ಕನ್ನಡ ಅರಿಮೆ ಪದವನ್ನು ಹುಡುಕಿ."
            }
            className={`search-input ${!isEnglish && "hide-text"}`}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          {!isEnglish && (
            <span
              className="search-input override"
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onKeyDownOrClickHandler(e);
                }
              }}
              onClick={focusOnSearch}
            >
              {searchedValueRef}
              {""}
              {isFocused && <span className="caret">|</span>}
            </span>
          )}
        </form>

        {searchedValue.length > 0 && (
          <CloseRoundedIcon
            className="clear-icon"
            onClick={clearHandler}
            tabIndex={0}
          />
        )}

        <FormControlLabel
          control={
            <Switch
              checked={isEnglish}
              onChange={switchOnChangeHandler}
              name="Lang"
            />
          }
          label={isEnglish ? "English" : "ಕನ್ನಡ"}
        />
      </div>

      {wordSuggestions?.length > 0 && (
        <div className="kannada-words-suggestion">
          {wordSuggestions.map((word, i) => (
            <div
              key={i}
              className="word-suggestion"
              onClick={onSuggestionSelectionHandler}
            >
              {word}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default React.memo(Search);
