import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectToastMessage,
  updateToastMessage,
} from "../slices/settingsSlice";

const ToastMessage = () => {
  const toastMessage = useSelector(selectToastMessage);
  const { show, message, additionalErrorMessages, isInfo, isSuccess, isError } =
    toastMessage;
  const dispatch = useDispatch();

  useEffect(() => {
    if (show && !isError) {
      setTimeout(() => {
        dispatch(
          updateToastMessage({
            show: false,
            message: "",
            additionalErrorMessages: [],
            isError: false,
            isInfo: false,
            isSuccess: false,
          })
        );
      }, 3000);
    }
  }, [show]);

  const closeToastMessage = () => {
    dispatch(
      updateToastMessage({
        show: false,
        message: "",
        additionalErrorMessages: [],
        isError: false,
        isInfo: false,
        isSuccess: false,
      })
    );
  };

  return (
    <>
      {show && (
        <Stack
          sx={{
            width: "300px",
            height: "fit-content",
            position: "fixed",
            top: 0,
            right: 0,
            margin: "1rem",
            maxHeight: "90vh",
            overflowY: "auto",
            borderRadius: 3,
          }}
          spacing={2}
          className="toast-message-wrapper"
        >
          <Alert
            variant="filled"
            severity={`${isSuccess ? "success" : isInfo ? "info" : "error"}`}
            sx={{ alignItems: "flex-start" }}
          >
            {message}
            {additionalErrorMessages?.length !== 0 && (
              <ol>
                {" "}
                {additionalErrorMessages?.map((str) => (
                  <li>{str}</li>
                ))}
              </ol>
            )}
            {isError && (
              <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                <Button
                  color="inherit"
                  variant="outlined"
                  size="small"
                  onClick={closeToastMessage}
                  sx={{
                    // backgroundColor: "white",
                    // color: "black",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  ಸರಿ
                </Button>
              </Stack>
            )}
          </Alert>
        </Stack>
      )}
    </>
  );
};

export default ToastMessage;
