import React, { useEffect, useState } from "react";

const YouTubePlayer = ({ urls }) => {
  const [videoUrls, setVideoUrls] = useState([]);

  useEffect(() => {
    if (urls.length > 0) {
      const embededUrls = [];
      urls.forEach((url) => {
        const videoId = url.split("v=")[1];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        embededUrls.push(embedUrl);
      });
      setVideoUrls(embededUrls);
    }
  }, [urls]);

  return (
    <>
      <h2 className="search-result-section-headers">ವೀಡಿಯೊ:</h2>
      <div className="searched-result-description">
        {videoUrls.map((videoUrl, i) => (
          <iframe
            src={videoUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            key={i}
            className="youtube-player"
          ></iframe>
        ))}
      </div>
    </>
  );
};

export default YouTubePlayer;
