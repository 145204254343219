import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableFooter, TablePagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./Glossary.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Glossary = ({ setSelectedWordAndLang }) => {
  const kannadaLetters = [
    "ಅ",
    "ಆ",
    "ಇ",
    "ಈ",
    "ಉ",
    "ಊ",
    "ಎ",
    "ಏ",
    "ಐ",
    "ಒ",
    "ಓ",
    "ಔ",
    "ಕ",
    "ಖ",
    "ಗ",
    "ಘ",
    "ಚ",
    "ಛ",
    "ಜ",
    "ಝ",
    "ಟ",
    "ಠ",
    "ಡ",
    "ಢ",
    "ತ",
    "ಥ",
    "ದ",
    "ಧ",
    "ನ",
    "ಪ",
    "ಫ",
    "ಬ",
    "ಭ",
    "ಮ",
    "ಯ",
    "ರ",
    "ಲ",
    "ವ",
    "ಶ",
    "ಸ",
    "ಹ",
    "ಳ",
  ];

  const englishLetters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const navigate = useNavigate();

  const backendAPI = `${process.env.REACT_APP_TYPESENSE_URL}/collections/${process.env.REACT_APP_DICTIONARY_COLLECTION_NAME}/documents/`;
  const typeSenseAPIKEY = process.env.REACT_APP_TYPESENSE_API_KEY;

  const [selectedKannadaLetter, setSelectedKannadaLetter] = useState("ಅ");
  const [selectedEnglishLetter, setSelectedEnglishLetter] = useState("ಅ");
  const [glossaryData, setGlossaryData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isKannada, setIsKannada] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalDataPresent, setTotalDataPresent] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let character = sessionStorage.getItem("letter-selected");
    let language = sessionStorage.getItem("language-selected");
    if (!character && !language) {
      fetchGlossaryData("Kannada", "ಅ", 50, 1);
    } else {
      fetchGlossaryData(language, character, rowsPerPage, pageNumber + 1);
      setSelectedKannadaLetter(character);
      setSelectedEnglishLetter(character);
      if (language === "Kannada") {
        setIsKannada(true);
      } else {
        setIsKannada(false);
      }
    }
  }, []);

  const onKannadaLetterSelect = (e, letter) => {
    e.preventDefault();
    setGlossaryData([]);
    setSelectedKannadaLetter(letter);
    setSelectedEnglishLetter("");
    setIsKannada(true);
    setNoDataFound(false);
    setPageNumber(0);
    setRowsPerPage(50);
    setTotalDataPresent(0);
    fetchGlossaryData("Kannada", letter, 50, 1);
    sessionStorage.setItem("letter-selected", letter);
    sessionStorage.setItem("language-selected", "Kannada");
  };
  const onEnglishLetterSelect = (e, letter) => {
    e.preventDefault();
    setGlossaryData([]);
    setSelectedEnglishLetter(letter);
    setSelectedKannadaLetter("");
    setIsKannada(false);
    setNoDataFound(false);
    setPageNumber(0);
    setRowsPerPage(50);
    setTotalDataPresent(0);
    fetchGlossaryData("English", letter, 50, 1);
    sessionStorage.setItem("letter-selected", letter);
    sessionStorage.setItem("language-selected", "English");
  };

  const fetchGlossaryData = async (language, letter, rowsCount, pageNum) => {
    let queryEndPoint = "",
      groupByEndPoint = "";
    if (language === "Kannada") {
      queryEndPoint = "initialKannadaLetter";
      groupByEndPoint = "searchKannadaWord";
    } else {
      queryEndPoint = "initialEnglishLetter";
      groupByEndPoint = "searchEnglishWord";
    }
    const data = await axios.get(
      `${backendAPI}/search?q=${letter}&query_by=${queryEndPoint}&group_by=${groupByEndPoint}&group_limit=1&per_page=${rowsCount}&page=${pageNum}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-TYPESENSE-API-KEY": typeSenseAPIKEY,
        },
      }
    );
    if (data?.status === 200 && data?.data?.grouped_hits?.length > 0) {
      const receivedData = [];
      setTotalDataPresent(data?.data?.found);
      data?.data?.grouped_hits?.forEach((groupedData) => {
        groupedData?.hits?.forEach((d) => {
          if (d?.document?.[queryEndPoint] === letter?.trim()) {
            receivedData.push(d);
          }
        });
      });
      setGlossaryData([...receivedData]);
      setNoDataFound(false);
    } else {
      setGlossaryData([]);
      setNoDataFound(true);
    }
  };

  const handleRowsPerPageChange = (e) => {
    e.preventDefault();
    setRowsPerPage(e.target.value);
    setPageNumber(0);
    let character = sessionStorage.getItem("letter-selected");
    let language = sessionStorage.getItem("language-selected");
    fetchGlossaryData(language, character, e.target.value, 1);
  };

  const handleChangePage = (e, pageNum) => {
    e.preventDefault();
    setPageNumber(pageNum);
    let character = sessionStorage.getItem("letter-selected");
    let language = sessionStorage.getItem("language-selected");
    const newPageNumber = pageNum > pageNumber ? pageNum + 1 : pageNumber;
    fetchGlossaryData(language, character, rowsPerPage, newPageNumber);
  };

  const handleWordSelected = (e, word, isKannada) => {
    e.preventDefault();
    setSelectedWordAndLang({ word, isKannada });
    navigate("/");
  };

  return (
    <div className="glossary-page-wrapper">
      <div className="glossary-page-section">
        <div className="letters-header">
          <div className="letters">
            {kannadaLetters.map((letter) => (
              <div
                className={`${
                  selectedKannadaLetter === letter && "selected-letter"
                } letter`}
                key={letter}
                onClick={(e) => onKannadaLetterSelect(e, letter)}
              >
                {letter}
              </div>
            ))}
          </div>
          <div className="letters">
            {englishLetters.map((letter) => (
              <div
                className={`${
                  selectedEnglishLetter === letter && "selected-letter"
                } letter`}
                key={letter}
                onClick={(e) => onEnglishLetterSelect(e, letter)}
              >
                {letter}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            height: "fit-content",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!noDataFound && glossaryData.length > 0 && (
            <TableContainer component={Paper} sx={{ maxWidth: 600 }}>
              <Table
                sx={{ minWidth: 300, maxWidth: "90vw" }}
                aria-label="glossary table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        width: isMobile ? "80px" : "100px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      ಅಕ್ಷರ
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      ಪದ
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {glossaryData.map((data, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {
                          data?.document?.[
                            isKannada
                              ? "initialKannadaLetter"
                              : "initialEnglishLetter"
                          ]
                        }
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className="word-cell"
                        onClick={(e) =>
                          handleWordSelected(
                            e,
                            data?.document?.[
                              isKannada ? "kannadaMeaning" : "englishMeaning"
                            ]?.[0],
                            isKannada
                          )
                        }
                      >
                        {
                          data?.document?.[
                            isKannada ? "kannadaMeaning" : "englishMeaning"
                          ]?.[0]
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={totalDataPresent}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[50]}
                      page={pageNumber}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleRowsPerPageChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
          {noDataFound && (
            <span className="no-data-glossary-message">
              ನೀವು ಆಯ್ಕೆ ಮಾಡಿದ ಅಕ್ಷರಕ್ಕೆ ಈಗ ನಮ್ಮ ಪದನರಕೆಯಲ್ಲಿ ಯಾವುದೇ ಪದಗಳಿಲ್ಲ.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Glossary;
