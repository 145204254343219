import React from "react";

const SearchDetailedDescription = ({
  detailedMeaningDescription,
  capitalizeFirstCharacterForEnglish,
  isEnglish,
}) => {
  return (
    <div className="searched-result-description">
      {typeof detailedMeaningDescription === "string" ? (
        <span className="description-text">
          &nbsp;&nbsp;&nbsp;&nbsp;{detailedMeaningDescription}
        </span>
      ) : (
        <ol>
          {detailedMeaningDescription?.map((description, i) => (
            <li key={i}>
              {isEnglish
                ? capitalizeFirstCharacterForEnglish(description, true)
                : description}
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};

export default SearchDetailedDescription;
