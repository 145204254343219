import { createSlice } from "@reduxjs/toolkit";
import {
  // decryptData,
  encryptData,
} from "../utils/utils";

const initialState = {
  userId: "",
  name: "",
  photoURL: "",
  emailId: "",
  mobileNumber: "",
  type: "",
  suggestionsGiven: [],
  suggestionsReceived: [],
  adminsList: [],
  superAdminsList: [],
  myBookMarks: [],
  apiKey: "",
  apiHashKey: "",
  csrfHashKey: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserDetails: (state, action) => {
      state.userId = action.payload.userId;
      state.name = action.payload.name;
      state.photoURL = action.payload.photoURL;
      state.emailId = action.payload.emailId;
      state.mobileNumber = action.payload.mobileNumber;
      state.type = action.payload.type;
      state.apiKey = action.payload.apiKey;
      state.myBookMarks = action.payload.myBookMarks || [];
      state.apiHashKey = encryptData(action.payload.apiKey);
      state.csrfHashKey = encryptData(action.payload.userId);
      if (action.payload.userId === "") {
        state.apiHashKey = "";
        state.csrfHashKey = "";
      }
    },
    updateSuggestionsGiven: (state, action) => {
      state.suggestionsGiven = action.payload;
    },
    updateSuggestionsReceived: (state, action) => {
      state.suggestionsReceived = action.payload;
    },
    pushToAdminList: (state, action) => {
      state.adminsList = action.payload;
    },
    pushToSuperAdminList: (state, action) => {
      state.superAdminsList = action.payload;
    },
    clearAdminsListAndSuperAdminsList: (state) => {
      state.adminsList = [];
      state.superAdminsList = [];
    },
    removeABookMark: (state, action) => {
      state.myBookMarks = action.payload.bookmarks?.filter(
        (data) => data.word !== action.payload.word
      );
    },
    addABookMark: (state, action) => {
      state.myBookMarks = [...action.payload.bookmarks, action.payload.data];
    },
  },
});

export const {
  updateUserDetails,
  updateSuggestionsGiven,
  updateSuggestionsReceived,
  pushToAdminList,
  pushToSuperAdminList,
  clearAdminsListAndSuperAdminsList,
  removeABookMark,
  addABookMark,
} = userSlice.actions;

export const selectUserData = (state) => state.user;
export const selectSuggestionsReceived = (state) =>
  state.user.suggestionsReceived;
export const selectSuggestionsGiven = (state) => state.user.suggestionsGiven;

export default userSlice.reducer;
