import { Box, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  deleteDoc,
  limit,
  getDocs,
  doc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { selectUserData } from "../slices/userSlice";
import { truncateText } from "../utils/utils";

const WordSuggestions = () => {
  const [words, setWords] = useState(null);
  const user = useSelector(selectUserData);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (user.type === "admin" || user.type === "super-admin") {
      const q = query(collection(db, "word-suggestions"), limit(25));
      const querySnapshot = await getDocs(q);
      const list = [];
      querySnapshot.docs.map((doc) => list.push({ ...doc.data(), id: doc.id }));
      setWords(list);
    }
  };

  const deleteSuggestion = async (e, documentData) => {
    e.preventDefault();
    try {
      const collectionRef = collection(db, "word-suggestions");
      const documentRef = doc(collectionRef, documentData.id);
      await deleteDoc(documentRef);
      alert("Data deleted successfully!");
      fetchData();
    } catch (error) {
      alert("Error deleting data!");
    }
  };

  return (
    <div className="suggestions-page-wrapper">
      {words && words?.length > 0 ? (
        <Box
          display="flex"
          flexWrap="wrap"
          gap={1}
          margin={"auto"}
          marginTop={2}
          alignItems={"flex-start"}
          justifyContent={"center"}
          height={"fit-content"}
          maxHeight={150}
        >
          {words?.map((data, index) => (
            <Chip
              label={truncateText(data.searchedWord)}
              key={index}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onDelete={(e) => deleteSuggestion(e, data)}
              deleteIcon={<DeleteIcon />}
            />
          ))}
        </Box>
      ) : (
        <span className="warning-text">ಯಾವುದೇ ಸಲಹೆಗಳಿಲ್ಲ</span>
      )}
    </div>
  );
};

export default WordSuggestions;
